import { Col, Container, Row } from "react-bootstrap";
import RowColFull from "../components/UI/RowColFull";
import Beitrittserklaerung from "../assets/files/Beitrittserklärung.pdf";
import Beitragsordnung from "../assets/files/Beitragsordnung.pdf";
import Satzung from "../assets/files/Satzung.pdf";
import ContactForm from "../components/contactForm/ContactForm";

export default function Contact() {
  return (
    <Container className="padtop10rem">
      <RowColFull>
        <h1>Kontaktiere uns!</h1>
      </RowColFull>
      <Row>
        <Col md={5}>
          <p>
            Du hast fragen oder möchtest uns unterstützen? Nimm Konakt zu uns
            auf.
          </p>
          <p>
            Du weißt schon, dass du eigentlich{" "}
            <strong className="d-purple">sofort Mitglied werden</strong>{" "}
            möchtest? Dann fülle unser{" "}
            <a
              className="d-purple"
              target="_blank"
              rel="noreferrer"
              href="./"
              onClick={() => window.open(Beitrittserklaerung)}
            >
              Beitrittsformular
            </a>{" "}
            aus und schicke es und direkt an{" "}
            <a className="d-purple" href="mailto:kontakt@queer-niederbayern.de">
              kontakt@queer-niederbayern.de
            </a>
            ! Alle weiteren Infos, die du brauchst findest du natürlich in
            unserer{" "}
            <a
              className="d-purple"
              target="_blank"
              rel="noreferrer"
              href="./"
              onClick={() => window.open(Beitragsordnung)}
            >
              Beitragsordnung
            </a>{" "}
            und unserer{" "}
            <a
              className="d-purple"
              target="_blank"
              rel="noreferrer"
              href="./"
              onClick={() => window.open(Satzung)}
            >
              Satzung
            </a>
            . Wir freuen uns auf dich.
          </p>
          <hr />
          <p>
            <a
              className="d-purple"
              href="https://strong-community.de/"
              target="_blank"
              rel="noreferrer"
            >
              Strong! LGBTIQ* Fachstelle gegen Diskriminierung und Gewalt
            </a>
            <br />
            Unterstützung, Information und Beratung für alle lesbischen,
            schwulen, bisexuellen, trans*, inter* und queeren Menschen, die
            Gewalt oder Diskriminierung erlebt haben - bayernweit!
            <br />
            Bayerisches Hilfe-Telefon:
            <a className="d-purple" href="tel:+4989856346427">
              {" "}
              +49 (0)89 856346427
            </a>
          </p>
        </Col>
        <Col md={{ span: 6, offset: 1 }}>
          {!isMobile() || (
            <>
              <hr></hr>
              <h2 className="padtopbottom1rem d-purple">Kontaktformular</h2>
            </>
          )}
          <ContactForm />
        </Col>
      </Row>
      <Row className="padtop2rem">
        <hr></hr>
      </Row>
    </Container>
  );
}

function isMobile() {
  let mobile = false;
  const mobileScreen = window.matchMedia("(max-width: 995px)");
  if (mobileScreen.matches) {
    mobile = true;
  }
  return mobile;
}
