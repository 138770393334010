import { Col, Row } from "react-bootstrap";
import SubPages from "../components/SubPages";
import MainNavigation from "../components/navigation/MainNavigation";
import Footer from "../components/footer/Footer";

function Error() {
  return (
    <>
      <MainNavigation />
      <main>
        <SubPages title="Seite nicht gefunden">
          <Row className="padtopbottom2rem">
            <Col md={{ span: 6, offset: 3 }}>
              <p className="center">
                Wir haben einige Änderungen vorgenommen, vermutlich ist diese
                Seite verschwunden oder hat einen neuen Link bekommen. Versuche
                deine Seite einfach über das Menü zu finden!
              </p>
            </Col>
          </Row>
        </SubPages>
      </main>
      <Footer />
    </>
  );
}
//TODO: write here that maybe the link was wrong and maybe my contact
export default Error;
