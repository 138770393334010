import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import RootLayout from "./pages/Root";
import Error from "./pages/Error";
import Links from "./pages/Links";
import Board from "./pages/Board";
import About from "./pages/About";
import Guidance from "./pages/Guidance";
import Terms from "./pages/Terms";
import CSDAndNews from "./pages/CSDAndNews";
import Dates from "./pages/Dates";
import News from "./pages/News";
import Contact from "./pages/Contact";
import Groups from "./pages/Groups";
import Koala from "./pages/Koala";
import Parent from "./pages/Parent";
import Gayfathers from "./pages/Gayfathers";
import Youthgroups from "./pages/Youthgroups";
import DataProtection from "./pages/DataProtection";
import Impressum from "./pages/Impressum";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import Activities from "./pages/Activities";
import CSD from "./pages/CSD";
import RegularsTable from "./pages/RegularsTable";
import Schools from "./pages/Schools";
import WorkGroups from "./pages/WorkGroups";
import YouthMeetings from "./pages/YouthMeetings";
import Goals from "./pages/Goals";
import { useEffect } from "react";

ReactGA.initialize("G-YFHZ4M2MN0");

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <Error />,
    children: [
      { index: true, element: <Home /> },
      { path: "/links", element: <Links /> },
      { path: "/board", element: <Board /> },
      { path: "/about", element: <About /> },
      { path: "/guidance", element: <Guidance /> },
      { path: "/terms", element: <Terms /> },
      { path: "/csdAndNews", element: <CSDAndNews /> },
      { path: "/news", element: <News /> },
      { path: "/dates", element: <Dates /> },
      { path: "/groups", element: <Groups /> },
      { path: "/youthgroups", element: <Youthgroups /> },
      { path: "/koalaTee", element: <Koala /> },
      { path: "/parentgroup", element: <Parent /> },
      { path: "/gayFathers", element: <Gayfathers /> },
      { path: "/contact", element: <Contact /> },
      { path: "/data-protection", element: <DataProtection /> },
      { path: "/impressum", element: <Impressum /> },
      { path: "/activities", element: <Activities /> },
      { path: "/csd", element: <CSD /> },
      { path: "/regulars-table", element: <RegularsTable /> },
      { path: "/schools", element: <Schools /> },
      { path: "/work-groups", element: <WorkGroups /> },
      { path: "/youth-meetings", element: <YouthMeetings /> },
      { path: "/goals", element: <Goals /> },
    ],
  },
]);

const CanonicalLink = ({ location }) => {
  useEffect(() => {
    const canonicalUrl = `${window.location.origin}${location.pathname}`;
    const canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      canonicalLink.setAttribute("href", canonicalUrl);
    } else {
      const newCanonicalLink = document.createElement("link");
      newCanonicalLink.setAttribute("rel", "canonical");
      newCanonicalLink.setAttribute("href", canonicalUrl);
      document.head.appendChild(newCanonicalLink);
    }
  }, [location.pathname]);

  return null;
};

function App() {
  return (
    <RouterProvider router={router}>
      <Helmet></Helmet>
      <CanonicalLink />
    </RouterProvider>
  );
}

export default App;
