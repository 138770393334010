import styles from "./PreLoader.module.css";

export default function PreLoader() {
  return (
    <div id={styles["loader-wrapper"]}>
      <div id={styles["loader"]}></div>
      <div className={[styles["loader-section"], styles["section-left"]]}></div>
      <div
        className={[styles["loader-section"], styles["section-right"]]}
      ></div>
    </div>
  );
}
