import { Form } from "react-bootstrap";

export default function ContactError(props) {
  return (
    <>
      {props.error ? (
        <Form.Text className={props.className + " warning"}>
          {props.children}
        </Form.Text>
      ) : (
        ""
      )}
    </>
  );
}
