import { Card, Col, Row } from "react-bootstrap";

/**
 *  Show an event date with certain colors on the left and some description text on the right
 *
 *  @param {String} events     event items consisting of day, dateDate, year and the text
 *
 *  @return     JSX
 */

const RawHtml = ({ children = "", tag: Tag = "div", ...props }) => (
  <Tag {...props} dangerouslySetInnerHTML={{ __html: children }} />
);

export default function EventDateAndText(props) {
  let myJSX = [];
  props.events.forEach((event, index) => {
    let color = getColor(index);
    myJSX.push(
      <div key={index}>
        <Row className="padtopbottom2rem">
          <Col md={{ span: 2, offset: 3 }}>
            <Card className="center custom-date-card">
              <Card.Header className={[color, "date-day"]}>
                {event.day}
              </Card.Header>
              <Card.Body className="date-date">{event.dateDate}</Card.Body>
              <Card.Footer className="date-year">{event.year}</Card.Footer>
            </Card>
          </Col>
          <Col md={{ span: 5 }} className="date-text">
            <RawHtml>{event.text}</RawHtml>
          </Col>
        </Row>
        {index + 1 === props.events.length ? (
          ""
        ) : (
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <hr></hr>
            </Col>
          </Row>
        )}
      </div>
    );
  });

  return <>{myJSX}</>;
}

/**
 *  Go through the colors and give back a background-color - used with modulo and starting with green
 */
function getColor(i) {
  let rest = i % 6;
  switch (rest) {
    case 0:
      return "bg-d-green";
    case 1:
      return "bg-d-blue";
    case 2:
      return "bg-d-purple";
    case 3:
      return "bg-d-pink";
    case 4:
      return "bg-d-orange";
    case 5:
      return "bg-d-yellow";
    default:
      return "bg-d-green";
  }
}
