import SubPages from "../components/SubPages";
import RowColFull from "../components/UI/RowColFull";

export default function RegularsTable() {
  return (
    <SubPages title="LSBTIQ*-Stammtische">
      <RowColFull>
        <p>
          Als gemeinnütziger Verein „Queer in Niederbayern e. V.“ sind wir die
          einzige Anlaufstelle für alle Menschen der LSBTIQ*-Community in ganz
          Niederbayern. Und für diese queeren Menschen, ihre Familien und
          Freunde, bieten wir aktuell in Landshut und in Straubing regelmäßig
          monatlich stattfindende „queere“ Stammtische an.
        </p>
        <p>
          Unser Angebot richtet sich an LSBTIQ*s und Freunde, die interessiert
          sind, sich in entspannter, respektvoller Atmosphäre zu treffen. Dabei
          stehen der Spaß und die gute Unterhaltung im Vordergrund. Wir wollen
          aber auch Raum zum Erfahrungsaustausch und für Fragen, z.B.
          hinsichtlich Coming-out, "schwule Väter", Diskriminierung am
          Arbeitsplatz, etc. in einem vertraulichen Umfeld anbieten.<br></br>
          Bei uns könnt ihr im parteipolitisch und konfessionell neutralen
          Umfeld andere lesbische, schwule, bisexuelle, inter- und
          transsexuelle, queere, aber auch heterosexuelle Leute kennen lernen.
          Denn Jede(r) soll die Möglichkeit haben, nach ihrer*seiner Art leben
          und lieben zu können.
        </p>
        <p>
          Nach dem Aufbau unserer LSBTIQ*-Jugendgruppen wollen wir allen
          LSBTIQ*-Menschen, die aus dem Jugendgruppenalter schon raus sind, die
          mit beiden Beinen fest im Leben stehen bzw. einfach Interesse haben,
          die Möglichkeit geben, sich mit anderen queeren Menschen aus der
          Region zu treffen, sich zu vernetzen und sich auszutauschen.
        </p>
        <hr></hr>
        <p>
          Queere Stammtische bieten wir monatlich momentan in Landshut und
          Straubing an.<br></br>
          Landshut: immer am 1. Dienstag im Monat ab 19:00 Uhr im Gasthaus
          „Weißes Bräuhaus Zum Krenkl“, Altstadt 107 in 84028 Landshut<br></br>
          Organisation: Herbert Lohmeyer –{" "}
          <a
            className="d-orange"
            href="mailto:stammtisch@queer-niederbayern.de"
          >
            stammtisch@queer-niederbayern.de
          </a>
          <br></br>
          Straubing: immer am 1. Samstag im Monat im Café Steiningers,
          Jakobsgasse 5 in 94315 Straubing<br></br>
          Organisation Straubing: Chris Hess, Amelie Kutz –{" "}
          <a
            className="d-orange"
            href="mailto:stammtisch@queer-niederbayern.de"
          >
            stammtisch@queer-niederbayern.de
          </a>
        </p>
      </RowColFull>
    </SubPages>
  );
}
