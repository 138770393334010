import { Col, Container, Row } from "react-bootstrap";
import styles from "./Pages.module.css";

export default function SubPages(props) {
  return (
    <>
      <div className={styles.subtoppad}>
        <Container className="padbottom2rem">
          <Row>
            <Col>
              <h1 className="pad custom">{props.title}</h1>
            </Col>
          </Row>

          {props.subtitle && (
            <>
              <Row className="padbottom2rem">
                <Col md={{ span: 8, offset: 2 }}>{props.subtitle}</Col>
              </Row>
              <hr />
            </>
          )}

          {props.children}
          <Row className="padtop2rem">
            <hr></hr>
          </Row>
        </Container>
      </div>
    </>
  );
}
