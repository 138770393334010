import SubPages from "../components/SubPages";
import RowColFull from "../components/UI/RowColFull";

const subtitle = (
  <p>
    Das Schulprojekt beschäftigt sich mit queerer Aufklärungsarbeit in Schulen
    aller Schularten. Dazu soll es Workshops für Klassen und Fortbildungen für
    Lehrkräfte geben. Die Workshops können auch in Berufsschulen und Vereinen
    gehalten werden.
  </p>
);

export default function Schools() {
  return (
    <SubPages title="Schulprojekt" subtitle={subtitle}>
      <RowColFull>
        <h3 className="d-orange">Unser Schulprojekt</h3>
        <p>
          Das Schulprojekt dient zur Aufklärung über queere Themen und zum
          Austausch zwischen Schüler*innen und Betroffenen. Deshalb werden
          unsere Workshops unter anderem peer-to-peer durchgeführt. Des Weiteren
          soll unser Schulprojekt eine Ergänzung zum Unterricht sein, da
          LGBTQIA* im Lehrplan kaum berücksichtigt wird. Queere Menschen werden
          in unserer Gesellschaft oft immer noch nicht akzeptiert. Darum ist es
          uns wichtig, junge Menschen und Lehrkräfte aufzuklären, um die
          Akzeptanz zu steigern. Unter{" "}
          <a
            className="d-orange"
            href="mailto:schulprojekt@queer-niederbayern.de"
          >
            schulprojekt@queer-niederbayern.de
          </a>{" "}
          können uns interessierte Schulen, Schulklassen und Lehrkräfte
          erreichen.
        </p>
        <p>Ansprechpersonen sind Lea Schröter und Chris Hess.</p>
        <p>
          Weiteres von uns findest du auch auf unseren Social-Media-Kanälen.
          <br></br>
          <strong className="d-orange">Wir freuen uns auf DICH!</strong>
        </p>
      </RowColFull>
    </SubPages>
  );
}
