import { Col, Row } from "react-bootstrap";
import SubPages from "../components/SubPages";
import GayFathers from "../assets/img/schwuleVaeter.png";

export default function Gayfathers() {
  return (
    <SubPages title="Schwule Väter und Ehemänner">
      <Row className="padtopbottom2rem">
        <Col md={6}>
          <p className="padtop1rem">
            Du bist Ehemann, Vater und schwul?
            <br />
            Du liebst deine Frau, aber schaust verstohlen anderen Männern nach?
            <br />
            Du weißt nicht so richtig, was mit dir los ist?
            <br />
            Du sorgst dich um die Zukunft von dir und deiner Familie?
            <br />
            Du weißt nicht, mit wem du darüber reden kannst?
            <br />
          </p>
          <p>
            Viele entdecken sich und ihre Gefühle erst spät, haben sie jahrelang
            unterdrückt oder nur heimlich gelebt – aus Angst vor der
            Gesellschaft, den Arbeitgeber*innen, der Familie…
            <br />
            Schwul zu sein, das ist heutzutage nicht mehr wirklich
            problematisch. Das ist von vielen Teilen der Gesellschaft schon
            längst akzeptiert. <br />
            Aber dabei auch noch mit einer Frau verheiratet zu sein oder sogar
            Kinder zu haben, das ist doch schon etwas anderes.
            <br />
            Du fühlst dich allein? Das muss nicht sein. Wir sind viele, denen es
            ebenso geht.
          </p>
        </Col>
        <Col md={6}>
          <p>
            <strong className="d-orange">
              Komm zu uns in die Gruppe „Schwule Väter“.
            </strong>
            <br />
            Wir Väter und Ehemänner können uns in dieser Gruppe gegenseitig
            unterstützen. Wenn Du wissen willst, wann und wo wir uns treffen,
            dann schreib uns doch einfach eine Nachricht an{" "}
            <a
              className="d-orange"
              href="mailto:schwule.vaeter@queer-niederbayern.de"
            >
              schwule.vaeter@queer-niederbayern.de
            </a>
            . <br />
            Natürlich können wir uns auch außerhalb der Gruppe in einem
            kleineren Rahmen treffen, wenn du nicht direkt in die Gruppe kommen
            möchtest.
            <br />
            Lesbische Mütter und Ehefrauen, trans*Ehepartner*innen können sich
            ebenfalls sehr gerne an uns wenden.
          </p>
          <img
            className="rewe"
            src={GayFathers}
            alt="Bild mit extra Mann in einer Familie"
          />
        </Col>
      </Row>
    </SubPages>
  );
}
