import { Col, Row } from "react-bootstrap";
import SubPages from "../components/SubPages";
import RowColFull from "../components/UI/RowColFull";

const subtitle = (
  <p>
    Unser Sohn bleibt unser Sohn, unsere Tochter bleibt unsere Tochter, egal wen
    sie lieben oder wer sie vorher waren. Es sind unsere Kinder und wir sind
    ihre Eltern.
  </p>
);

export default function Parent() {
  return (
    <SubPages title="Elternstammtisch" subtitle={subtitle}>
      <RowColFull className="padtopbottom2rem center">
        <iframe
          src="https://www.youtube.com/embed/DhfjjNcr3PE"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          title="Video for Parents"
        />
      </RowColFull>
      <hr />
      <RowColFull className="padtopbottom2rem">
        <p>
          Unser Elternstammtisch richtet sich an Eltern schwuler, lesbischer,
          bi-, trans*-, intersexueller und allen "queeren" Kinder und
          Jugendlichen zum Austausch und zur gegenseitigen Hilfe.<br></br>
          Organisation des Elternstammtisches. Herbert Lohmeyer -{" "}
          <a
            className="d-orange"
            href="mailto:elternstammtisch@queer-niederbayern.de"
          >
            elternstammtisch@queer-niederbayern.de
          </a>
        </p>
        <p>
          Sich bei Unsicherheit und vielen auftretenden Fragen Hilfe zu suchen
          und mit anderen, auch bei heiklen Themen das Gespräch zu finden,
          dieser Weg ist sicher nicht leicht.
        </p>
        <p>
          Anders zu sein, das gleiche Geschlecht zu lieben oder sich selbst im
          falschen Körper zu finden, ist und bleibt ein schwieriges Thema. Auch
          wenn die letzten Jahre viele positive Veränderungen mit sich brachten.
          Das ist letztlich erst ein Aufbruch. Wir sind noch weit von Akzeptanz
          und wirklicher Toleranz entfernt.
        </p>
        <p>
          Unser Elternstammtisch soll daher an Bedeutung gewinnen. Gerade weil
          ein Coming-Out heute viel früher passiert und nicht unten den Tisch
          gekehrt werden darf, wächst im Umfeld die Unsicherheit, damit umgehen
          zu müssen. Rauszugehen aus der Enge des Alltags und unter
          Gleichbetroffenen Rat, Mut, Zuversicht und Gemeinschaft zu finden, ist
          eine gute Sache.
        </p>
        <p>
          Das wollen wir mit unserem Elternstammtisch anbieten. Sind Sie auch
          dabei?
        </p>
        <p>
          Dann melden Sie sich per Mail bei uns unter{" "}
          <a
            className="d-orange"
            href="mailto:elternstammtisch@queer-niederbayern.de"
          >
            elternstammtisch@queer-niederbayern.de
          </a>
          , direkt über unsere{" "}
          <a className="d-orange" href="/contact">
            Kontaktseite
          </a>
          , per WhatsApp oder mit einem Anruf an unseren Vorsitzenden Herbert
          unter{" "}
          <a className="d-orange" href="tel:+491723280621">
            0172 / 3280621
          </a>
          .
        </p>
      </RowColFull>
      <hr />
      <RowColFull className="padtopbottom2rem">
        <h3 className="d-orange">Was ein Outing für Eltern bedeutet</h3>
        <p>
          Es war mehr Zufall als geplant: Susanne*, Mutter zweier Söhne aus
          Schleswig-Holstein, war gerade dabei die Betten zu beziehen, als sie
          den Geldbeutel ihres damals 15-Jährigen fand - und darin ein
          Liebesgedicht an einen Mann.
          <br />
          "Ich habe ihn darauf angesprochen, aber er wollte nicht mit mir
          darüber reden», erzählt sie heute. Über seine Religionslehrerin habe
          sie später erfahren, dass ihre Vermutung stimmte. «Es war schlimm,
          dass ein fremder Mensch mehr über mein Kind wusste als ich."
          <br />
          Susanne* kreisten danach viele Gedanken durch den Kopf: Was, wenn sie
          auf natürlichem Wege keine Enkel bekommen kann? Was, wenn ihr Kind auf
          der Straße angefeindet wird oder ein Arbeitgeber negativ reagiert?
          Schlimm sei diese Zeit für sie gewesen.
          <br />
          Lesen Sie{" "}
          <a
            className="d-orange"
            href="https://va.newsrepublic.net/s/hNdsTsR"
            target="_blank"
            rel="noreferrer"
          >
            die ganze Geschichte.
          </a>
        </p>
        <p className="small">* Name durch die Onlineredaktion geändert</p>
      </RowColFull>
      <hr />
      <Row className="padtopbottom2rem">
        <Col md={5}>
          <p>
            <strong className="d-orange">
              Wir sind ein Teil von Queer in Niederbayern e.V.
            </strong>{" "}
            und wollen uns gerne regelmäßig bedarfsorientiert treffen.
          </p>
          <p>
            Unsere Elternstammtische finden momentan noch in unregelmäßigen
            Abständen statt. Sie werden derzeit bedarfsorientiert organisiert.
            <br />
            <strong>
              Wenn auch du/ihr Interesse oder Bedarf an einem Austausch mit
              anderen betroffenen Eltern habt und euch einen Elternstammtisch
              wünscht, dann meldet euch einfach bei uns per Mail bei unter{" "}
              <a
                className="d-orange"
                href="mailto:elternstammtisch@queer-niederbayern.de"
              >
                elternstammtisch@queer-niederbayern.de
              </a>
              , direkt über unsere{" "}
              <a className="d-orange" href="/contact">
                Kontaktseite
              </a>
              , per WhatsApp oder mit einem einfachen Anruf an unseren
              Vorsitzenden Herbert unter Tel.{" "}
              <a class="d-orange" href="tel:+491723280621">
                0172 / 328 06 21
              </a>
              .
              <a
                className="d-orange"
                href="https://www.facebook.com/queerniederbayern/"
                target="_blank"
                rel="noreferrer"
              >
                Facebook-Profil
              </a>{" "}
              und bei{" "}
              <a
                className="d-orange"
                href="https://www.instagram.com/queer_niederbayern/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>{" "}
              mit.
            </strong>
          </p>
        </Col>
        <Col md={{ span: 5, offset: 2 }}>
          <p>
            <strong className="d-orange">Was wir nicht sind!</strong>
          </p>
          <p>
            Wir sind weder Experten noch Fachkräfte. Aber wir können als Eltern,
            jeder in seiner Weise, darüber berichten, wie wir das Outing unserer
            Kinder erlebt haben, zum Teil immer noch durchleben und wie wir
            damit umgehen.
          </p>
        </Col>
      </Row>
    </SubPages>
  );
}
