import { Col, Container, Row } from "react-bootstrap";
import styles from "./Pages.module.css";

export default function MainPages(props) {
  return (
    <>
      <div className={styles[props.topImage]}></div>
      <div className="custom-shadow">
        <Container className="padbottom2rem">
          <Row>
            <Col>
              <h1 className="pad custom">{props.title}</h1>
            </Col>
          </Row>
          {props.special && (
            <Row className="padbottom2rem">{props.subtitle}</Row>
          )}
          {props.subtitle && !props.special && (
            <Row className="padbottom2rem">
              <Col md={{ span: 8, offset: 2 }}>{props.subtitle}</Col>
            </Row>
          )}
          {props.children}
        </Container>
      </div>
      <div className={styles[props.bottomImage]}></div>
    </>
  );
}
