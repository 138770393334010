import RowColFull from "../components/UI/RowColFull";
import MainPages from "../components/MainPages";

const subtitle = (
  <p>
    Im Juli 2021 hat die Mitgliederversammlung auf Antrag des Vorstandes unsere
    Vereinssatzung um die Jugendparagraphen ergänzt. Seither haben wir im Verein
    eine überwiegend selbständige Jugendabteilung. Diese vertritt alle unsere
    Mitglieder bis 27 Jahren, die sich auch in den Jugendgruppen organisieren.
    Unsere Jugendabteilung hatte sich dann den Namen „Queere Jugend
    Niederbayern“ gegeben.
  </p>
);

export default function Youthgroups() {
  return (
    <MainPages
      topImage="term-img"
      bottomImage="term-img2"
      title="Vereinsjugend"
      subtitle={subtitle}
    >
      <hr></hr>
      <RowColFull>
        <h3 className="d-yellow padtop2rem">
          Jugendabteilung „Queere Jugend Niederbayern“
        </h3>
        <p>Hey ihr Lieben,</p>
        <p>
          schön, dass du auf die Seite der Jugendgruppen gestoßen bist.<br></br>
          Wir veranstalten Treffen für junge queere Menschen von 14 bis 27. Egal
          ob du dich als lesbisch, schwul, bi, pan, trans*, inter*, nicht binär
          oder queer identifizierst, du kannst gerne jederzeit vorbeikommen. Und
          auch wenn du dich in keine Schublade stecken lassen willst oder dir
          noch nicht ganz sicher bist, wozu du dich zählst, laden wir dich
          herzlich ein.
        </p>
        <p>
          Wir sind eine bunt gemischte Truppe, bei der sich jede*r aufgehoben
          fühlen darf. Da wir noch sehr junge Gruppen sind, darfst du uns gerne
          deine Ideen und Verbesserungsvorschläge mitbringen.
        </p>
        <p>
          Wir bemühen uns, unsere Treffen so barrierefrei wie möglich zu
          gestalten. Wenn du diesbezüglich Bedürfnisse hast, darfst du uns gerne
          kontaktieren und wir finden gemeinsam die beste Lösung.
        </p>
        <p>
          Wenn Du also Interesse an unserer Jugendabteilung hast, Du zwischen
          ca. 14 und 27 Jahren bist, wenn Du dir unsicher bist oder sonstige
          Fragen hast, dann melde Dich unter{" "}
          <a
            className="d-yellow"
            href="mailto:jugendgruppen@queer-niederbayern.de"
          >
            jugendgruppen@queer-niederbayern.de
          </a>{" "}
          bei uns.
        </p>
      </RowColFull>
      <hr></hr>
      <RowColFull>
        <h3 className="d-yellow padtop2rem">
          Jugendnetzwerk Lambda Bayern e. V.
        </h3>
        <p>
          Das Jugendnetzwerk Lambda Bayern e.V. ist der Dachverband der
          LGBTIQ-Jugendgruppen in Bayern (LGBTIQ = lesbisch, schwul/gay,
          bisexuell, trans*, inter* , queer) und  Teil des bundesweiten
          Jugendnetzwerk Lambda e.V.. Wir sind Jugendliche und junge Erwachsene,
          die ehrenamtlich und nach dem peer-to-peer-Ansatz queere Jugendarbeit
          gestalten - von jungen Menschen für junge Menschen. Gegründet wurden
          wir 2002 in München, richtig los mit der aktiven Jugendarbeit ging es
          dann 2005. Als gemeinnützig anerkannter Verband und Mitglied im
          Bayerischen Jugendring fördern wir die Gleichstellung und Akzeptanz
          von LGBTIQ-Jugendlichen in Bayern.
        </p>
        <p>
          Wir sind mit allen unseren Jugendgruppen Mitglied im Jugendnetzwerk
          Lambda Bayern. Und zwei der Vorstände von Lambda Bayern e. V. kommen
          aus unserer Jugendabteilung, nämlich Anschana und Lukas.<br></br>
          Mehr über das Jugendnetzwerk Lambda Bayern e. V. findet ihr unter{" "}
          <a
            className="d-yellow"
            href="https://www.lambda-bayern.de/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.lambda-bayern.de/
          </a>
        </p>
      </RowColFull>
      <hr></hr>
      <RowColFull>
        <h3 className="d-yellow padtop2rem">Bezirksjugendring Niederbayern</h3>
        <p>
          Seit Der Herbstvollversammlung 2023 des Bezirksjugendring Niederbayern
          sind wir auch Mitglied im Bezirksjugendring. Damit sind wir in Bayern
          und bundesweit der erste queere Verein, der mit Sitz und Stimme in
          einem Bezirksjugendring Mitglied ist. Und unser Mitglied Lea ist
          seither auch im Vorstand des Bezirksjugendrings{" "}
          <a
            className="d-yellow"
            href="https://www.bezjr-niederbayern.de/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.bezjr-niederbayern.de/
          </a>
        </p>
      </RowColFull>
    </MainPages>
  );
}
