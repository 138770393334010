import { Col, Row } from "react-bootstrap";
import uuid from "react-uuid";

export default function RowColResponsiveText(props) {
  let myJSX = [];
  const chunkSize = Number(props.chunksize) || 2;

  for (let i = 0; i < props.items.length; i += chunkSize) {
    const chunks = props.items.slice(i, i + chunkSize);

    myJSX.push(
      <Row key={uuid()} className={props.className || "padtopbottom1rem"}>
        {chunks.map(({ item, header, color }) => (
          <Col md key={uuid()}>
            {header ? (
              <h3 className={color || props.color || "d-pink"}>{header}</h3>
            ) : (
              ""
            )}
            <ul>
              <li>{item}</li>
            </ul>
          </Col>
        ))}
      </Row>
    );
  }

  return <>{myJSX}</>;
}
