import { Col, Row } from "react-bootstrap";
import styles from "../components/Guidance.module.css";
import MainPages from "../components/MainPages";
import Up2You from "../assets/img/Logo_UP2you_Claim_RGB.jpg";
import ProFamiliaQin from "../assets/img/profamiliaUndQin.png";
import CouplePic from "../assets/img/guidance1.jpg";
import TwoPeoplePic from "../assets/img/guidance2.png";
import SonAndMother from "../assets/img/guidance3.png";
import Room from "../assets/img/beratungsraum.jpg";
import Bayern from "../assets/img/StFAS.png";

const subtitle = (
  <>
    <Col md={7}>
      <img className="news padbottom2rem" src={Up2You} alt="Logo up2you" />
      <p className="center">
        Die Beratungsstelle für queere Menschen, deren soziales Umfeld
        <br />
        sowie für Fachkräfte in Niederbayern ist ein Beratungsangebot
        <br />
        der pro familia Niederbayern e. V. in Kooperation mit
        <br />
        Queer in Niederbayern e. V.
      </p>
    </Col>
    <Col md={5}>
      <img
        className="news margintop4rem"
        src={ProFamiliaQin}
        alt="Logo Pro Familia & Queer in Niederbayern e.V."
      />
    </Col>
  </>
);

export default function Guidance() {
  return (
    <MainPages
      topImage="guidance-img"
      bottomImage="guidance-img2"
      title="up2you"
      subtitle={subtitle}
      special={true}
    >
      <hr />

      <Row className="padtopbottom1rem">
        <Col md>
          <h3 className="d-green">Wen wir beraten</h3>
          <ul className={styles.list}>
            <li className={styles.bullet}>
              Queere Menschen: also Menschen, die sich nicht als heterosexuell
              oder cisgeschlechtlich identifizieren (Schwule, Lesben, bi- und
              asexuelle Menschen sowie trans*, inter*, nonbinäre und agender
              Menschen)
            </li>
            <li className={styles.bullet}>
              Das Umfeld von queeren Menschen: Familie, Freunde, Bekannte und
              Verwandte von queeren Menschen
            </li>
            <li className={styles.bullet}>
              Fachkräfte, die Kontakt zu queeren Menschen haben: z.B.
              Lehrer*innen, Erzieher*innen, Psycholog*innen,
              Sozialarbeiter*innen uvm.
            </li>
          </ul>
          <p>Unabhängig von Religion, Hautfarbe, Herkunft und Alter. </p>
        </Col>
        <Col md>
          <img
            className={styles.guidance}
            src={CouplePic}
            alt="Lesbisches Pärchen"
          />
        </Col>
      </Row>

      <hr />

      <Row className="padtopbottom1rem">
        <Col md>
          <img
            className={styles.guidance1}
            src={TwoPeoplePic}
            alt="Zwei queere Menschen"
          />
        </Col>
        <Col md>
          <h3 className="d-green">Was wir anbieten</h3>
          <p>
            Wir beraten, informieren und unterstützen Sie in allen Themen, die
            Sie zu uns bringen, z.B.:
          </p>
          <ul className={styles.list}>
            <li className={styles.bullet}>
              bei Fragen rund um den Transitionsprozess
            </li>
            <li className={styles.bullet}>
              beim Identitätsfindungsprozess (inneres Coming Out)
            </li>
            <li className={styles.bullet}>beim äußeren Coming Out</li>
            <li className={styles.bullet}>bei Diskriminierungserfahrungen</li>
            <li className={styles.bullet}>
              bei Fragen zur (Regenbogen-) Familienplanung
            </li>
          </ul>
        </Col>
      </Row>
      <hr />
      <Row className="padtopbottom1rem">
        <Col md>
          <h3 className="d-green">Was noch wichtig ist</h3>
          <ul className={styles.list}>
            <li className={styles.bullet}>
              wir unterliegen der Schweigepflicht und beraten kostenlos und auf
              Wunsch anonym
            </li>
            <li className={styles.bullet}>wir beraten auch auf Englisch</li>
            <li className={styles.bullet}>
              Dolmetscher*innen können für andere Sprachen kostenlos angefordert
              werden
            </li>
            <li className={styles.bullet}>
              Unsere Räume, inklusive der Toilette, sind leider nicht komplett
              barrierefrei. Im Erdgeschoss ist jedoch eine barrierefreie
              Beratung möglich.
            </li>
          </ul>
        </Col>
        <Col md>
          <img
            className={styles.guidance1}
            src={SonAndMother}
            alt="Sohn und Mutter"
          />
        </Col>
      </Row>
      <hr />
      <Row className="padtopbottom1rem">
        <Col md={4}>
          <img
            className={styles.guidance2}
            src={Room}
            alt="Raum für Beratungsstelle"
          />
        </Col>
        <Col md={{ span: 7, offset: 1 }}>
          <h3 className="d-green">Wo Sie uns finden</h3>
          <p>
            up2you
            <br />
            Grasgasse 331a
            <br />
            84028 Landshut
            <br />
            E-Mail:{" "}
            <a
              className="d-green"
              href="mailto:samira.heber@profamilia.de"
              target="_blank"
              rel="noreferrer"
            >
              samira.heber@profamilia.de
            </a>
            <br />
            Telefon:{" "}
            <a
              className="d-green"
              href="tel:+49871206508-60"
              target="_blank"
              rel="noreferrer"
            >
              0871/206508-60
            </a>
          </p>
          <p>
            Unsere Räume befinden sich in einem Durchgang Richtung
            Taubengässchen. <br />
            Wir sind die letzte Tür auf der rechten Seite.
          </p>
        </Col>
      </Row>
      <hr />
      <Row className="padtopbottom2rem">
        <Col md={{ span: 8, offset: 2 }}>
          <p className="center">
            Ihr wollt noch etwas mehr wissen? Seht euch unseren Bericht <br />{" "}
            <a
              className="d-green"
              href="https://www.br.de/nachrichten/bayern/queere-beratungsstelle-in-niederbayern-die-erste-ihrer-art,SwrSAfQ"
              target="_blank"
              rel="noreferrer"
            >
              "Die erste ihrer Art: Queere Beratungsstelle in Niederbayern"
            </a>{" "}
            von BR24 an.
          </p>
        </Col>
      </Row>
      <hr />
      <Row className="padtopbottom2rem">
        <Col md>
          <p>
            Die Beratungsstelle wird durch das Bayerische Staatsministerium für
            Familie, Arbeit und Soziales gefördert.
          </p>
        </Col>
        <Col md>
          <img
            className={styles.stfas}
            src={Bayern}
            alt="Logo Bayrisches Staatsministerium für Familie, Arbeit und Soziales"
          />
        </Col>
      </Row>
    </MainPages>
  );
}
