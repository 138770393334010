import SubPages from "../components/SubPages";
import RowColFull from "../components/UI/RowColFull";

const subtitle = (
  <p>
    Liebe*r Besucher*in, liebe*r Interessent*in,<br></br>
    wir freuen uns über Dein Interesse an unserem gemeinnützigen Verein "Queer
    in Niederbayern e. V.", der die einzige Anlaufstelle für lesbische, schwule,
    bi-, trans- und intersexuellen sowie allen queeren (LSBTIQ*) Menschen in
    Niederbayern ist.
  </p>
);

export default function About() {
  return (
    <SubPages title="Wir über uns" subtitle={subtitle}>
      <RowColFull className="padtopbottom2rem">
        <p>
          Der gemeinnützige Verein Queer in Niederbayern e. V. ist mittlerweile
          DIE etablierte Anlaufstelle für die LGBTIQ-Community in Niederbayern.
          Wir fördern und fordern die Akzeptanz und Gleichstellung der
          LSBTIQ*-Lebensweise (engl.: lesbisch, schwul, bisexuell, transgender,
          inter, queer) in der Öffentlichkeit und im Alltag. Niemand soll
          aufgrund der sexuellen Orientierung oder Geschlechtsidentität
          diskriminiert werden – weder rechtlich noch gesellschaftlich.
        </p>
        <p>
          Neben einem Austausch und einer Vernetzung innerhalb der queeren
          Community wollen wir einen Beitrag zu einer toleranten, respektvollen
          und vielfältigen Gesellschaft in Niederbayern leisten. Queer in
          Niederbayern e. V. will queeren Menschen ein Gesicht geben und durch
          Aufklärung homo- und transfeindlicher Diskriminierung entgegenwirken.
          Wir wollen uns von Geschlechterrollen emanzipieren und künstliche
          Zuweisungen in Hinblick auf geschlechtliche Identität und sexuelle
          Orientierung aufdecken und in Frage stellen.
        </p>
        <p>
          Queer in Niederbayern e. V. ist ausschließlich ehrenamtlich
          organisiert. Wir verstehen uns als Plattform zur Umsetzung queerer
          Ideen. Unsere{" "}
          <a className="d-pink" href="work-groups">
            Arbeitsgruppen
          </a>{" "}
          freuen sich jederzeit über engagierte Leute, die Lust haben eigene
          Ideen einzubringen oder bei unseren Projekten mitzumachen.
        </p>
        <p>
          Seit unserer Gründung haben sich viele Aktivitäten ergeben, von
          kulturellen Veranstaltungen (Lesungen, Filmvorführungen, etc.), über
          Gruppenangebote (queere Jugendgruppen, trans*-Gruppe,
          Elternstammtisch, LSBTIQ*-Stammtische, Gruppe „schwule Väter und
          Ehemänner, etc.) und politische Aktionen (Christopher Street Day,
          Internationaler Tag gegen Homo- und Transfeindlichkeit am 17. Mai
          (IDAHOBIT), zum Coming-Out-Day am 11. Oktober, Podiumsdiskussionen,
          etc.) bis hin zu Aufklärungsprojekten (Schulprojekte peer-to-peer,
          etc.) reichen.
        </p>
        <p>
          Seit dem 01. August 2021 gibt es auf unsere Initiative hin die queere
          Beratungsstelle für ganz Niederbayern – up2you in Landshut. Hier sind
          Queer in Niederbayern e. V. und pro familia Niederbayern e. V. die
          beiden gleichberechtigten Co-Träger des bis zu 90% staatlich
          geförderten Projektes.
        </p>
        <p>
          Bei vielen queeren Projekten steht der Austausch zwischen Community
          und der heteronormativen Gesellschaft im Fokus. Die fehlende Begegnung
          erzeugt Fremdheit und diese ist Boden für Ängste, Ressentiments und
          Diskriminierung. Wir wollen den Austausch, die Konfrontation, die
          Diskussion und gemeinsames Engagement!
        </p>
        <p>
          So sind wir mittlerweile die größte queere Organisation in
          Niederbayern mit über 200 Mitgliedern in allen kreisfreien Städten und
          in allen Landkreisen geworden.
        </p>

        <p>
          Der Verein ist Mitglied im{" "}
          <a
            className="d-pink"
            href="https://www.lsvd.de/"
            target="_blank"
            rel="noreferrer"
          >
            Lesben- und Schwulenverband Deutschland (LSVD, Landesverband Bayern)
          </a>
          , dem{" "}
          <a
            className="d-pink"
            href="https://csd-deutschland.de/"
            target="_blank"
            rel="noreferrer"
          >
            CSD Deutschland e. V.
          </a>
          , Jugendnetzwerk{" "}
          <a
            className="d-pink"
            href="https://www.lambda-bayern.de/"
            target="_blank"
            rel="noreferrer"
          >
            Lambda e. V.
          </a>{" "}
          und seit Herbst 2023 sind wir mit unserer Jugendabteilung als erste
          queere Organisation bundesweit auch Mitglied in einem
          Bezirksjugendring (Bezirksjugendring Niederbayern).
        </p>
      </RowColFull>
    </SubPages>
  );
}
