import { Col } from "react-bootstrap";
import PicLink from "../UI/PicLink";

/**
 *  Simple footer picture component with link (adds a col with span 1 to PicLink)
 *
 *  @param {String} link      Link to page
 *  @param {String} className class names added to jsx
 *  @param {String} src       picture source, use a variable to make this possible with react
 *  @param {String} alt       alternative text for accessability
 *  @param {String} linkText  Sometimes used instead of alttext, just uses the linktext and adds " Logo"
 *
 *  @return     JSX
 */

export default function FooterPic(props) {
  return (
    <Col md={{ span: 1 }} className="footerComponent">
      <PicLink
        link={props.link}
        className={props.className || "footerpic"}
        src={props.src}
        alt={props.alt || `${props.linkText} Logo`}
      />
    </Col>
  );
}
