import { useState } from "react";
import Footer from "../components/footer/Footer";
import MainNavigation from "../components/navigation/MainNavigation";
import { Outlet } from "react-router-dom";
import PreLoader from "../components/preLoader/PreLoader";
import styles from "../components/preLoader/PreLoader.module.css";
import { HelmetProvider } from "react-helmet-async";

function RootLayout() {
  const [loaded, setLoaded] = useState(false);

  setTimeout(() => {
    setLoaded(true);
  }, 500);

  return (
    <HelmetProvider>
      <div className={loaded ? styles.loaded : ""}>
        <PreLoader />
        <MainNavigation />
        <main>
          <Outlet />
        </main>
        <Footer />
      </div>
    </HelmetProvider>
  );
}

export default RootLayout;
