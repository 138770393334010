import { Col, Row } from "react-bootstrap";
import SubPages from "../components/SubPages";
import RowColFull from "../components/UI/RowColFull";
import TextAndPic from "../components/UI/TextAndPic";
import CSDPic from "../assets/img/La_2023.png";
import BayernWinter from "../assets/img/bayern_winter.jpg";
import Refugees from "../assets/img/refugeesWelcome.png";

const subtitle = (
  <p>
    Wir möchten unseren Verein ständig weiterentwickeln. Unsere Arbeitsweise
    wollen wir offen und transparent gestalten. Deshalb bieten wir allen
    Interessierten verschiedene Arbeitsgruppen an. Beteiligen können sich alle
    Menschen, die sich einbringen wollen, besonders auch unsere Mitglieder, die
    nicht im Vorstand eingebunden sind. Wenn du dich einbringen und/oder
    mitarbeiten möchtest oder erstmal Fragen dazu hast, dann schreib uns eine
    Mail an{" "}
    <a className="d-orange" href="mailto:kontakt@queer-niederbayern.de">
      kontakt@queer-niederbayern.de
    </a>
  </p>
);

export default function WorkGroups() {
  return (
    <SubPages title="Arbeitsgruppen" subtitle={subtitle}>
      <RowColFull>
        <h4 className="d-orange">AG Christopher-Street-Day</h4>
        <p>
          Zur Organisation unserer niederbayerischen Christopher-Street-Days
          (Landshut, Straubing, Kelheim) richten wir Organisationsteams ein. Die
          Aufgabe der AG CSD ist es, die größten LSBTIQ*-Evente in Niederbayern,
          unsere Christopher-Street-Days in Kelheim, Straubing und Landshut, zu
          planen und organisieren.
        </p>
        <p>
          <strong>
            Mit unseren Christopher-Street-Days verfolgen wir mehrere Ziele:
          </strong>
        </p>
        <ul className="orange-list">
          <li>
            Wir wollen öffentlich auf die politisch und gesellschaftlich
            bestehende Diskriminierung von LGBTIQ* aufmerksam machen und deren
            Abbau einfordern.
          </li>
          <li>
            Wir wollen Schwule, Lesben, Bisexuelle, trans*-, inter- und
            non*binäre sowie alle queeren Menschen in Niederbayern sichtbar
            machen.
          </li>
          <li>
            Wir wollen Gruppen, Vereine und Organisationen, in denen
            LGBTIQ*-Themen eine Rolle spielen, bekanntmachen und miteinander
            vernetzen.
          </li>
          <li>
            Wir wollen für Kultur (Musik, Kunst, Demonstrations-Kultur) von und
            für LGBTIQ*s eine große und öffentliche Plattform bieten
          </li>
          <li>
            Wir wollen mit vielen verschiedenen, respektierenden,
            wertschätzenden und toleranten Menschen gemeinsam unsere Vorstellung
            einer diskriminierungsfreien Gesellschaft feiern.
          </li>
        </ul>
        <p>
          <strong>Wer wir sind und wie wir arbeiten:</strong>
        </p>
        <TextAndPic
          linkText="CSD Plakat"
          text="Wir sind eine Gruppe aus ganz unterschiedlichen Menschen. Wir engagieren uns alle ehrenamtlich im CSD-Team und niemand bekommt Geld für seine Mitarbeit. Alle bringen verschiedene Erfahrungen, Kompetenzen und Ideen mit. Die meisten von uns haben beim CSD ihre ersten Erfahrungen zur Organisation einer Großveranstaltung gesammelt. In unserer CSD-Arbeitsgruppe teilen wir die Aufgaben untereinander auf und bilden Kleingruppen für bestimmte Themen (Bühne und Technik, Gastronomie, Demo-Parade, Öffentlichkeitsarbeit etc.). Für unsere Zusammenarbeit haben wir uns eine feste Struktur gegeben. Wir beraten unsere Ideen und treffen unsere Entscheidungen demokratisch. Zur Koordination unserer AGs wird eine AG-Leitung gewählt, die auch die Aufgabe hat als Kontakt für Interessierte und andere Vereine ansprechbar zu sein."
          src={CSDPic}
          className="vorstand"
        />
        <p>
          <strong>Neugierig geworden?</strong>
        </p>
        <p>
          Wenn du dich für unsere Arbeit interessierst, Fragen hast oder mal in
          eine AG-Sitzung reinschnuppern willst, kannst du einfach{" "}
          <a className="d-orange" href="/contact">
            Kontakt
          </a>{" "}
          zu uns aufnehmen. Wir freuen uns über neue Menschen, die auch neue
          Ideen in den CSD einbringen oder lernen wollen, wie man ein solches
          Event organisiert.
        </p>
        <hr></hr>
        <h4 className="d-orange">AG Kultur und Community</h4>
        <p>
          Wir wollen Farbe in Niederbayerns Alltag bringen!<br></br>
          Die AG Kultur und Community sorgt für Begegnung und Austausch, für
          Unterhaltung und Bewegung. Wir stellen eigene Aktionen auf die Beine,
          aber sind auch eine Plattform für jede*n von Euch, die*der etwas vor
          hat, das Queer in Niederbayern bewegen soll.
        </p>
        <p>
          <strong>Was wir wollen...</strong>
        </p>
        <p>
          Niederbayern soll kulturell bunter und queerer werden! Und wir wollen
          dafür sorgen… durch eigene Angebote, durch Vernetzung und Förderung
          auf verschiedenen Wegen. Niederbayern braucht eine queere Community!
          Und wir wollen dafür sorgen… durch Treffpunkte, Ausflüge und andere
          Kontaktmöglichkeiten.
        </p>
        <p>
          <strong>Was wir machen...</strong>
        </p>
        <TextAndPic
          linkText="CSD Plakat"
          text=" Den ersten Kontakt mit uns haben die meisten von Euch bei unseren
          monatlichen Stammtischen, den queeren Jugendgruppen oder anderen
          Gruppen. Der Vorteil: es wird nie langweilig. Du lernst immer ein neue
          Leute aus und außerhalb der LSBTIQ*-Community kennen. Und bei
          möglichen wechselnden Orten für unsere Stammtische lernst du auch
          immer wieder neue Café oder Restaurant kennen in einer Region
          Niederbayerns, in der du vorher vielleicht auch noch nicht so oft
          warst.
          
          Unsere Ausflüge sollen Dich über das Jahr begleiten. Wir suchen das
          Schönste aus jeder Jahreszeit raus – sei es ein Osterpicknick,
          Wanderungen durch die niederbayerischen Regionen, evtl.
          Schlittschuhlaufen, Bowlen oder vieles mehr. Eine schöne Gelegenheit
          neue Leute kennenzulernen und vielleicht neue Freunde zu finden.

          Verschiedene kulturelle Angebote wie Lesungen oder Theaterbesuche
          sollen unser Programm ergänzen. Und wir unterstützen viele andere
          Veranstaltungen aus dem Umfeld von Queer in Niederbayern."
          src={BayernWinter}
          className="vorstand"
        />
        <p>
          <strong>Was du tun kannst...</strong>
        </p>
        <p>
          Es gibt viele Wege bei uns mitzumachen und wir laden Dich herzlich
          dazu ein! Fühlst du dich angesprochen, die in dieser AG zu engagieren?
          <br></br>
          Als Mitglied unserer Arbeitsgruppe entwickelst Du mit uns neue
          Veranstaltungen oder bekannte Veranstaltungsreihen weiter, damit wir
          unsere Ziele weiterverfolgen können. Dabei kann man eine ganze Menge
          lernen: Eventmanagement, Organisation, Marketing, Texten,
          Bühnentechnik und Künstlersuche. Wir wollen eine offene, demokratische
          und kreative Arbeitsgruppe bilden und freuen uns auf deine Mitarbeit!
          <br></br>
          Als Ideengeber helfen wir dir deine Idee umzusetzen und die Mitglieder
          von Queer in Niederbayern e. V. als auch Niederbayerns Community von
          deiner Idee zu überzeugen. Sei es eine Sportgruppe zu gründen oder ein
          Community-Event …, wir helfen dir bei Planung, Umsetzung, Marketing
          und allem Drumherum.
        </p>
        <p>
          <strong>Wie erreicht man uns...</strong>
        </p>
        <p>
          Per E-Mail über{" "}
          <a className="d-orange" href="mailto:kontakt@queer-niederbayern.de">
            kontakt@queer-niederbayern.de
          </a>
          <br></br>
          Per Post über die Adresse des Vereins<br></br>
          Und natürlich in unseren dreiwöchentlichen und stets mitgliederoffenen
          Vorstandssitzungen. Achtung! Wir treffen uns an wechselnden Orten – in
          Präsenz und online – also im Kalender nachsehen oder am besten noch
          per E-Mail nachfragen.
        </p>
        <hr></hr>
        <h4 className="d-orange">AK Öffentlichkeitsarbeit + Social Media</h4>
        <p>
          Der AK Öffentlichkeitsarbeit + Social Media ist im Auftrag des
          Vorstands tätig und zuständig für die Bewerbung des Vereins, unserer
          Veranstaltungen sowie für die Social Media Kommunikation von Queer in
          Niederbayern e.V.
        </p>
        <p>
          <strong>Was sind unsere Aufgaben im Verein?</strong>
        </p>
        <p>
          Mit vielen unserer Anliegen und Aktivitäten wollen wir eine breite
          Öffentlichkeit erreichen. Darüber hinaus wollen wir Queer in
          Niederbayern noch bekannter machen und Menschen für unsere Sache
          gewinnen und für ein Engagement in unserem Verein begeistern.
          <br></br>
          Der AK Öffentlichkeitsarbeit kümmert sich genau darum: Aufmerksamkeit
          zu erreichen und Queer in Niederbayern ein Gesicht nach außen zu
          geben.
          <br></br>
          So schaffen wir Kommunikationswege für alle Teile von Queer in
          Niederbayern. Ob Flyer, Aufkleber oder Infostände, ob Homepage,
          Facebook oder Instagram. Wir sind für jede Werbe-Idee zu haben.
        </p>
        <p>
          <strong>Warum brauchen wir dich?</strong>
        </p>
        <p>
          Wir sind kreativ tätig. Und je mehr wir sind, desto mehr Ideen. Egal,
          ob du Spaß hast am designen oder kommunizieren. Wenn du Lust und Spaß
          daran hast, andere für Queer in Niederbayern zu begeistern, dann
          solltest du dringend bei uns reinschnuppern!
        </p>
        <p>
          <strong>Schau vorbei!</strong>
        </p>
        <p>
          Bei Interesse schreib uns einfach an{" "}
          <a className="d-orange" href="mailto:kontakt@queer-niederbayern.de">
            kontakt@queer-niederbayern.de
          </a>
          . Wir freuen uns auf dich!
        </p>
        <hr></hr>
        <h4 className="d-orange">AG Foto</h4>
        <p>
          Wir möchten ein Team an Fotograph*innen zusammenstellen, die bei
          Veranstaltungen des Vereins Fotos und ggf. auch Videos machen und
          diese dem Verein für Website, Social Media, Flyer, etc. zur Verfügung
          stellen. Du hast Spaß daran, tolle Fotos und spannende Videos zu
          drehen? Dann melde dich gerne bei uns.
        </p>
        <hr></hr>
        <h4 className="d-orange">AK Rainbow Refugees</h4>
        <TextAndPic
          linkText="Refugees Welcome"
          text="Diese Gruppe Refugees nimmt sich queeren Geflüchteten und deren Bedürfnissen an und stellt die Verbindung zum Verein her. Der Verein wurde schon mehrfach um Hilfe gebeten, wenn es um queere Geflüchtete in Niederbayern geht. Auch die Zusammenarbeit mit dem Migrationsbeirat u. a. der Stadt Landshut, erfolgt durch diese AG."
          src={Refugees}
          className="news"
        />
        <p>
          <strong className="orange">Deutsch</strong>
        </p>
        <p>
          In Deutschland sind Homosexualität, Bisexualität und Transsexualität
          legal.<br></br>
          Die Gesellschaft ist größtenteils sehr tolerant und niemand darf wegen
          der sexuellen Orientierung und geschlechtlichen Identität
          diskriminiert werden.
          <br></br>Homosexuelle Paare können heiraten und Trans*Menschen haben
          legalen Zugang zu Hormonen und geschlechtsangleichenden Operationen.
        </p>
        <p>
          Niederbayern ist eine sehr bunte und offene Gesellschaft. Die
          LGBTIQ*-Community feiert jedes Jahr den Christopher Street Day in
          Landshut, Straubing und Kelheim und es gibt viele weitere Events für
          Schwule, Lesben, Bisexuelle und Trans*- Inter*- und queere Menschen.
          Es gibt auch queere Jugendgruppen, LSBTIQ*-Stammtische und eine
          Beratungsstelle.
        </p>
        <p>
          <strong>
            Queer in Niederbayern ist eine Organisation für LGBTIQ*-Menschen.
            Wir setzen uns für dich ein und helfen, wo wir können.
            <br></br>
            Wenn du eine Frage hast, dann schreib uns.<br></br>
            Wenn du Hilfe brauchst, dann rufe uns.<br></br>
            Wenn du allein bist, dann komm zu uns.<br></br>
          </strong>
          <br></br>
        </p>
        <p>
          Wir freuen uns, dass du hier bist und wir sind für dich da.<br></br>
          Email:{" "}
          <a className="d-orange" href="mailto:kontakt@queer-niederbayern.de">
            kontakt@queer-niederbayern.de
          </a>
          <br></br>
          Telefon: 0172 / 328 06 21
        </p>
        <p>
          <strong className="orange">English</strong>
        </p>
        <p>
          In Germany homosexuality, bisexuality and transsexuality is legal.
          Society has a very tolerant attitude towards people and gender
          identity is recognized as among the prohibited grounds of
          discrimination.<br></br>
          Homosexual couples can marry and transsexuals have legal access to
          hormones and also gender reassignment surgery.
        </p>
        <p>
          Niederbayern is a varied and open society. Every year the
          LGBTIQ*-community celebrates the Gay Pride/Christopher Street Day in
          Landshut, Straubing und Kelheim and there are much more events for
          queer people.<br></br>
          There are self-help-groups and advisory centres as well.
        </p>
        <p>
          <strong>
            Queer in Niederbayern e. V. is an (non-profit-making) organisation
            for LGBTIQ*-people. We stand up for you and help wherever we can.
            <br></br>
            If you have any questions, feel free to contact us via
            e-mail/Whatsapp/SMS!<br></br>
            If you need help, please call!<br></br>
            If you are lonely, come over!<br></br>
          </strong>
          <br></br>
        </p>
        <p>
          <strong>
            We are pleased to have you in Niederbayern and we are there for you!
          </strong>
          <br></br>
          Email:{" "}
          <a className="d-orange" href="mailto:kontakt@queer-niederbayern.de">
            kontakt@queer-niederbayern.de
          </a>
          <br></br>
          Telefon: +49 172 / 328 06 21
        </p>
        <p>
          <strong className="orange">Francais</strong>
        </p>
        <p>
          En Allemagne, l’homosexualité, la bisexualité et la transexualité sont
          légales. La société est très tolérante et toute discrimination est
          interdite.
          <br></br>Les couples homosexuels peuvent marier et les transsexuels
          ont un accès légal aux hormones ainsi qu’aux opérations de
          réassignation sexuelle.
        </p>
        <p>
          Niederbayern est une ville très ouverte et haute en couleur. La
          communauté LGBT célèbre chaque année la Gay Pride et beaucoup d’autres
          évènements sont organisés pour les homosexuels, lesbiennes, bisexuels
          et transgenres. Il est aussi possible de participer à des groupes de
          soutien ou de se faire conseiller directement.
        </p>
        <p>
          <strong>
            Queer in Niederbayern e. V. est une organisation pour personnes
            LGBT. Nous nous engageons pour toi et t’apportons notre aide dans la
            mesure du possible.
            <br></br>
            Si tu as une question, écris-nous.<br></br>
            Si tu as besoin d’aide, appelle-nous.<br></br>
            Si tu es seul, rejoins-nous.<br></br>
          </strong>
        </p>
        <p>
          <strong>
            Nous sommes contents que tu sois là et nous sommes là pour toi.
          </strong>
          <br></br>
          e-mail:{" "}
          <a className="d-orange" href="mailto:kontakt@queer-niederbayern.de">
            kontakt@queer-niederbayern.de
          </a>
          <br></br>
          Tél: +49 172 / 328 06 21
        </p>
        <p>
          <strong className="orange">Türkçe</strong>
        </p>
        <p>
          Almanya’da hiç bir eşcinsellik yasak del. Burda yaşan Insanlar çok
          hoşgörülüdür, ve birini ayırt etmek yasak!<br></br>Eşcinsel Çiftler
          için gayretten bir evlilik var ve transseksüellere hormonlar ve
          cinsiyet değiştirmeye yardım var. Harran problemlere yardım grupları
          var.
        </p>
        <p>
          Niederbayern çok renkli ve açık bir şehir, ve LGBT Communityci her
          sene Christopher Street Day-ı kutluyor, ve da çok eşcinsel Event-leri
          var.
        </p>
        <p>
          <strong>
            Queer in Niederbayern her türlü bir cinselliklere bir organizasyon.
            Biz sana her türlü problemde yardım ederiz. Bir sorun varsa, bize
            haber ver.
            <br></br>
            Yardıma ihtiyatın oldu zaman bizi ara.<br></br>
            Yalnız oldun zaman bize gel.<br></br>
            Hoşgeldin, iyiki varsin.<br></br>
          </strong>
        </p>
        <p>
          e-mail:{" "}
          <a className="d-orange" href="mailto:kontakt@queer-niederbayern.de">
            kontakt@queer-niederbayern.de
          </a>
          <br></br>
          Tel: +49 172 / 328 06 21
        </p>

        <hr></hr>
        <h4 className="d-orange">AG Zukunft</h4>
        <p>
          Mit der AG Zukunft stellen wir uns eine Arbeitsgruppe vor, die
          gemeinsam z. B. in kleinen Workshops Ideen und Themen für die
          Weiterentwicklung unseres gemeinnützigen Vereins entwickelt. Diese
          sollen dann im AK Zukunft zusammen mit dem Vorstand bearbeitet und bis
          zur Umsetzung entwickelt werden.
        </p>
        <p>
          Wir könnten z. B. mal eine Klausur durchführen, in der sich Vorstand,
          Jugendvorstand, andere AGs und auch unsere bisher eher nicht aktiven
          Mitglieder über die Zukunft unseres Vereins austauschen können. In
          einem Workshop können viele Ideen und Themen gesammelt werden. Die AG
          Zukunft greift dann diese Themen und Punkte auf und entwickelt Ideen
          für deren Umsetzung. Zum AK Zukunft laden wir euch alle herzlich ein,
          gemeinsam mit uns konkrete Themen zu entwickeln und zu diskutieren.
        </p>
        <hr></hr>
        <h4 className="d-orange">AG Queere Bildung und Politik</h4>
        <p>
          Die AG Queere Bildung und Politik trägt zur politischen Willensbildung
          unseres Vereins bei und ermöglicht ein stärkeres Agieren. Die AG
          entwickelt Formate, die zum Austausch, zur Diskussion und zur
          kritischen Auseinandersetzung mit queeren Themen und Diskursen
          einladen. So wird die queerpolitische Bildung in der Community
          gefördert und die eigene politische Agenda weiterentwickelt. Zudem
          erarbeitet die AG unsere politischen Positionen, mit denen wir als
          Community öffentlich auf politische Entscheidungsträger*innen
          einwirken. Dabei werden auch Bündnisse mit anderen Verbänden und
          Vereinen der queeren Community geschlossen.<br></br>
          Haben wir dein Interesse geweckt? Dann schreib uns eine{" "}
          <a className="d-orange" href="/contact">
            Mail
          </a>
          .
        </p>
        <hr></hr>
        <h4 className="d-orange">Gleichstellungsbeauftragte*r</h4>
        <p>
          Ein Mensch, der darauf schaut, dass Minderheiten und von
          Diskriminierung betroffene Menschen (z.B. FLINTA*, BIPoCs, behinderte
          Menschen) im Verein mitgedacht werden und unsere Veranstaltungen
          möglichst inklusiv zu gestalten. Dies wäre eher eine einzelne Person
          als eine Gruppe. Wenn du gerne mitmachen möchtest, komme doch zu
          unserer nächsten mitgliederoffenen Vorstandssitzung.
        </p>
        <hr></hr>
      </RowColFull>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <p>
            Natürlich freuen wir uns auch, wenn sich jemand in einer bereits
            bestehenden Gruppe einbringen möchte.
          </p>
          <p>
            Spricht dich eine unserer AKs an? Hast du hierzu Ideen? Hast du
            Lust, dich hier einzubringen? Dann melde dich bei uns{" "}
            <a className="d-orange" href="mailto:kontakt@queer-niederbayern.de">
              kontakt@queer-niederbayern.de
            </a>
          </p>
        </Col>
      </Row>
    </SubPages>
  );
}
