import { Col, Row } from "react-bootstrap";
import RowColFull from "../components/UI/RowColFull";
import RowColResponsiveText from "../components/UI/RowColResponsiveText";
import SubPages from "../components/SubPages";

const subtitle = (
  <p>
    Wir sind Queer in Niederbayern e.V. und haben uns am 18. Juni 2019
    gegründet.
    <br />
    Wir sind ausschließlich ehrenamtlich organisiert und möchten allen
    Motivierten die Möglichkeit geben sich zu engagieren.
    <br />
    Die Mitglieder bestimmen in den Mitgliederversammlungen die Entwicklung des
    Vereins und unsere regelmäßigen Vorstandssitzungen sind in der Regel für
    alle Mitglieder offen.
    <br />
    Wenn du unsere Ziele unterstützen möchtest, freuen wir uns, dich als
    Mitglied oder als finanzielle Unterstützer*in begrüßen zu dürfen.
    <br />
    Die Ziele unseres Vereins:
  </p>
);

export default function Goals() {
  return (
    <SubPages title="Ziele" subtitle={subtitle}>
      <Row>
        <Col md={{ span: 4, offset: 2 }}>
          <p>
            Wir stehen dafür ein, in der Öffentlichkeit bestehende Vorurteile
            gegenüber queeren Menschen (Lesben, Schwulen, Bi-, Trans-, Inter-
            und Asexuellen (LSBTIQ*)) abzubauen und der gruppenbezogenen
            Menschenfeindlichkeit entgegenzuwirken.
          </p>
        </Col>
        <Col md={{ span: 4, offset: 1 }}>
          <p>
            Wir treten ein für Akzeptanz und Offenheit gegenüber queeren*
            Menschen und queeren* Lebensformen in Niederbayern und weltweit.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4, offset: 2 }}>
          <p>
            Wir treten gegen die Stigmatisierung von Menschen mit HIV und AIDS
            und anderen sexuell übertragbaren Krankheiten ein.{" "}
          </p>
        </Col>
        <Col md={{ span: 4, offset: 1 }}>
          <p>Wir zeigen Solidarität mit den Opfern anti-queerer Straftaten.</p>
        </Col>
      </Row>
      <hr />
      <RowColFull className="padtopbottom2rem">
        <h2 className="center d-blue">Forderungen</h2>
        <p className="padtopbottom2rem">
          Queer in Niederbayern steht für die Vielfalt queerer* Lebensentwürfe
          und Lebensformen, für Freiheit sowie ein gutes Leben jenseits von
          Gewalt und Diskriminierung.
          <br />
          Wir stehen ein für eine Gesellschaft, in der alle Menschen unabhängig
          von ihrer Hautfarbe, ihrer Nationalität, ihrer Religion, körperlichen
          Einschränkungen, ihrer geschlechtlichen oder sexuellen Orientierung
          leben können.
          <br />
          Wir fordern:
        </p>
      </RowColFull>
      <RowColResponsiveText
        chunksize="3"
        items={[
          {
            item: "dass alle Menschen sicher leben können. Niemand soll sich vor körperlicher oder seelischer Gewalt fürchten müssen, egal ob körperlich, seelisch oder verbal.",
          },
          {
            item: "eine Veränderung der Entscheidungspraxis für queere* geflohene Menschen. Insbesondere muss die Verfolgung auf Grund von sexueller und geschlechtlicher Identität als Fluchtgrund anerkannt werden.",
          },
          {
            item: "ein Ende der Diskriminierung von Menschen aufgrund ihres Geschlechts. Niemand darf aufgrund ihrer Geschlechtszugehörigkeit unterdrückt, benachteiligt oder anders behandelt werden.",
          },
          {
            item: "dass Hassverbrechen gegenüber queeren* Menschen als solche benannt und verfolgt werden.",
          },
          {
            item: "eine vorurteilsfreie Behandlung von queeren* Menschen im Gesundheitswesen.",
          },
          {
            item: "ein Verbot sogenannter „Umpolungs- und Konversionstherapien“ für queere* Menschen.",
          },
          {
            item: "ein Verbot nicht notwendiger medizinischer Eingriffe an intergeschlechtlichen Kindern.",
          },
          {
            item: "die Abschaffung des Transsexuellengesetzes und die Erarbeitung eines Gesetzes zur einfachen Anerkennung der Geschlechteridentität.",
          },
          {
            item: "ein konsequentes Eintreten gegen jede Form gruppenbezogener Menschenfeindlichkeit.",
          },
          {
            item: "dass die hier benannten Probleme als gesamtgesellschaftliche Probleme begriffen werden und dementsprechend auch als solche behandelt werden",
          },
          {
            item: "die rechtliche Anerkennung von Lebens- und Familienformen abseits der gesellschaftlichen/überholter Normvorstellungen und deren gesellschaftliche Anerkennung sowie einen respektvollen Umgang mit ihnen.",
          },
          {
            item: "die Änderung der bayerischen Bildungspläne. Lebens- und Familienvorstellung abseits der Norm müssen in der Kinder- & Jugendbildung selbstverständlich werden.",
          },
        ]}
      />
      <Row>
        <Col md={{ span: 4, offset: 4 }}>
          <ul>
            <li>den effektiven Schutz queerer Geflüchteter in Unterkünften.</li>
          </ul>
        </Col>
      </Row>
    </SubPages>
  );
}
