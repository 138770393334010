import { Col, Row } from "react-bootstrap";
import MainPages from "../components/MainPages";

const subtitle = (
  <>
    <p>Herzlich willkommen, schön, dass ihr da seid.</p>
    <p>
      Wir von "Queer in Niederbayern e. V." wollen euch und für alle
      Altersgruppen auch abseits der Christopher-Street-Days und spezieller
      thematischer Veranstaltung ein breites Angebot für Treffen, Kennenlernen,
      den Austausch, gemeinsame Unternehmungen und vieles Andere bieten. Hier
      informieren wir euch nicht nur über unsere{" "}
      <a className="d-blue" href="/youthgroups">
        queeren Jugendgruppen
      </a>{" "}
      und den{" "}
      <a className="d-blue" href="/parentgroup">
        Elternstammtisch
      </a>
      , sondern auch über unsere LSBTIQ*-Stammtische und andere regelmäßigen
      Angebote für die queere Community und für alle interessierten und
      unterstützenden Menschen und Freunde.
    </p>
  </>
);

export default function Groups() {
  return (
    <MainPages
      topImage="meeting-img"
      bottomImage="meeting-img2"
      title="Treffen"
      subtitle={subtitle}
    >
      <hr className="padtopbottom1rem" />
      <Row className="padtopbottom1rem">
        <Col md>
          <p>
            Unsere Jugendgruppen für Jugendliche und junge Erwachsene von 14-27
            Jahren. Egal ob schwul, lesbisch, bisexuell, trans* oder queer.
            <br />
            <a className="d-blue" href="/youthgroups">
              Zu den Jugendgruppen!
            </a>{" "}
          </p>
        </Col>
        <Col md>
          <p>
            Ein Stammtisch für Eltern schwuler, lesbischer, bi-, trans-,
            intersexueller, oder aller "queeren" Kinder und Jugendlichen zum
            Austausch und zur gegenseitigen Hilfe. <br />
            <a className="d-blue" href="/parentgroup">
              Zum Elternstammtisch.
            </a>
          </p>
        </Col>
      </Row>
      <Row className="padtopbottom1rem">
        <Col md>
          <p>
            Die Anlaufstelle für trans*, inter* und nicht-binäre Menschen in
            Niederbayern
            <br />
            <a className="d-blue" href="/koalaTee">
              Zu koalaTee.
            </a>
          </p>
        </Col>
        <Col md>
          <p>
            Du bist Ehemann, Vater und schwul?
            <br />
            Du liebst deine Frau, aber schaust verstohlen anderen Männern
            nach?,Du weißt nicht so richtig, was mit dir los ist? Du sorgst dich
            um die Zukunft von dir und deiner Familie?
            <br />
            <a className="d-blue" href="/gayFathers">
              Zu "schwule Väter und Ehemänner".
            </a>
          </p>
        </Col>
      </Row>
      <hr className="padtopbottom1rem" />
    </MainPages>
  );
}
