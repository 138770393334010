import { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import ContactError from "./ContactError";
import emailjs from "@emailjs/browser";

export default function ContactForm() {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    mail: "",
    subject: "",
    message: "",
    honeypot: "",
    deletion: "",
  });

  const [error, setError] = useState({
    name: "",
    mail: "",
    subject: "",
    message: "",
    honeypot: "",
    valid: false,
  });

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return { ...prevFormData, [name]: type === "checkbox" ? checked : value };
    });

    setError((prevError) => {
      return { ...prevError, [name]: "" };
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const serviceId = process.env.REACT_APP_EMAIL_SERVICE_KEY;
    const templateId = process.env.REACT_APP_EMAIL_TEMPLATE_KEY;
    const publicKey = process.env.REACT_APP_EMAIL_API_KEY;

    const formErrors = validations(formData);
    setError(formErrors);

    if (formErrors.valid) {
      console.log("Form is valid. Submitting..."); //TODO: loading functionality
      formData.deletion = setDeletionText(formData.deletion);
      emailjs
        .send(serviceId, templateId, formData, publicKey)
        .then(() => {
          setSubmitted(true);
          setFormData({
            name: "",
            mail: "",
            subject: "",
            message: "",
            honeypot: "",
            deletion: "",
          });
          setLoading(false);
        })
        .catch((err) => {
          <div className="alert alert-danger">
            <p>
              Da ist was total schief gelaufen :( sende uns gern einfach eine
              Mail!
              {err}
            </p>
          </div>;
        });
    } else {
      return (
        <div className="alert alert-danger">
          <p>
            Da ist was total schief gelaufen :( sende uns gern einfach eine
            Mail!
          </p>
        </div>
      );
    }
  }

  if (submitted) {
    return (
      <div className="alert alert-success">
        <p>
          Danke! Wir werden so schnell wie möglich auf deine E-Mail antworten.
        </p>
      </div>
    );
  }

  return (
    <Form onSubmit={handleSubmit} method="POST">
      <Form.Group className="mb-3" controlId="name">
        <Form.Control
          type="text"
          placeholder="Name"
          name="name"
          htmlFor="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <ContactError error={error.name}>
        Bitte gib einen gültigen Namen an.
      </ContactError>
      <Form.Group className="mb-3" controlId="email">
        <Form.Control
          type="email"
          placeholder="beispiel@beispiel.com"
          name="mail"
          htmlFor="email"
          value={formData.mail}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <ContactError error={error.mail}>
        Bitte gib eine valide Mailadresse an.
      </ContactError>
      <Form.Group className="mb-3" controlId="subject">
        <Form.Control
          type="text"
          placeholder="Betreff"
          name="subject"
          htmlFor="subject"
          value={formData.subject}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <ContactError error={error.subject}>
        Bitte gib einen Betreff an.
      </ContactError>
      <Form.Group className="mb-3" controlId="message">
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Deine Nachricht"
          name="message"
          htmlFor="message"
          value={formData.message}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <ContactError error={error.message}>
        Bitte gib eine Nachricht ein (diese darf aus Sicherheitsgründen keine
        Webadresse enthalten). Du kannst uns auch per Mail erreichen.
      </ContactError>
      <Form.Group id="honeypot">
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Bitte hier nicht ausfüllen"
          id="capt"
          name="honeypot"
          htmlFor="honeypot"
          value={formData.honeypot}
          onChange={handleChange}
        />
        <ContactError error={error.honeypot}>
          Freaking bot (╯°□°）╯︵ ┻━┻.
        </ContactError>
      </Form.Group>
      <Form.Group className="mb-3" controlId="text">
        <p>
          Ich bin mit der Speicherung meiner personenbezogenen Daten im Rahmen
          der Interessentenbetreuung einverstanden.
          <br />
          Ich möchte dass meine Daten
        </p>
      </Form.Group>
      <Form.Group controlId="kindOfDeletion">
        <Form.Check
          type="radio"
          name="deletion"
          id="sixM"
          value="sixM"
          label="nach 6 Monaten gelöscht werden."
          onChange={handleChange}
          checked={formData.deletion === "sixM"}
        />
        <Form.Check
          type="radio"
          name="deletion"
          id="twentyFour"
          value="twentyFour"
          label="nach 24 Monaten gelöscht werden."
          onChange={handleChange}
          checked={formData.deletion === "twentyFour"}
        />
        <Form.Check
          type="radio"
          name="deletion"
          id="never"
          value="never"
          label="nie gelöscht werden."
          onChange={handleChange}
          checked={formData.deletion === "never"}
        />
      </Form.Group>
      {loading ? (
        <Button disabled variant="primary" className="contact submit-btn">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="loading"
          />
          Senden
        </Button>
      ) : (
        <Button
          variant="primary"
          type="submit"
          id="submit"
          className="contact submit-btn"
        >
          Senden
        </Button>
      )}
    </Form>
  );

  function validations(formData) {
    let validMailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const errors = {
      name:
        formData.name.trim() === "" ||
        containsNumber(formData.name.trim()) ||
        containsWeirdKeyword(formData.name.trim()),
      mail: formData.mail.trim() === "" || !formData.mail.match(validMailRegex),
      subject:
        formData.subject.trim() === "" ||
        formData.subject.trim().includes("www") ||
        formData.subject.trim().includes(".com") ||
        containsWeirdKeyword(formData.subject.trim()),
      message:
        formData.message.trim() === "" ||
        formData.message.trim().includes("www") ||
        formData.message.trim().includes(".com") ||
        containsWeirdKeyword(formData.message.trim()),
      honeypot: formData.honeypot.trim() !== "",
    };

    // Check if any of the errors are true
    errors.valid = Object.values(errors).every((value) => !value);

    return errors;

    function containsNumber(str) {
      const regex = /\d/;
      return regex.test(str);
    }

    function containsWeirdKeyword(str) {
      const keywords = [
        "function ",
        "var ",
        "const ",
        " if ",
        " else ",
        " for ",
        "while",
        "console.",
        "log(",
        "buy",
        "reseller",
        "price",
        "Miieefjief",
      ];
      const lowerStr = str.toLowerCase();
      return keywords.some((keyword) => lowerStr.includes(keyword));
    }
  }
}

function setDeletionText(deletion) {
  if (deletion === "sixM") {
    return "Nach 6 Monaten";
  } else if (deletion === "twentyFour") {
    return "Nach 24 Monaten";
  } else {
    return "Nie";
  }
}
