import SubPages from "../components/SubPages";
import RowColFull from "../components/UI/RowColFull";
import Vorstand from "../assets/img/Foto_Vorstand_30-07-2023.jpg";

export default function Board() {
  return (
    <SubPages title="Vorstand">
      <RowColFull className="padtop2rem">
        <img
          className="vorstand"
          src={Vorstand}
          alt="Foto des aktuellen Vorstands"
        ></img>
        <p className="small center">
          Von links nach rechts: Katharina Bergmeier, Christoph Jahn, Karin
          Stempfhuber-Herde, Anja König (Wahlleitung), <br />
          Herbert Lohmeyer, Richard Hertlin, Wiebke Fitter, Enrico Weiser,
          Robert Forster, Amelie Kutz, Chris Hess, Lisa Sommer
          <br />
          Foto: Lukas Hollering
        </p>
      </RowColFull>

      <RowColFull>
        <h3 className="d-pink padtop2rem">Die Vorsitzenden</h3>
        <p>
          Herbert Lohmeyer | n.n.
          <br />
          Mail:{" "}
          <a className="d-pink" href="mailto:kontakt@queer-niederbayern.de">
            kontakt@queer-niederbayern.de
          </a>
        </p>
      </RowColFull>
      <RowColFull>
        <h3 className="d-pink padtop2rem">
          Die stellvertretenden Vorsitzenden
        </h3>
        <p>Richard Hertlin | Chris Hess</p>
      </RowColFull>

      <RowColFull>
        <h3 className="d-pink padtop2rem">Unser Schatzmeister (Kassier)</h3>
        <p>
          Christoph Jahn | Mail:{" "}
          <a className="d-pink" href="mailto:finanzen@queer-niederbayern.de">
            finanzen@queer-niederbayern.de
          </a>
        </p>
      </RowColFull>

      <RowColFull>
        <h3 className="d-pink padtop2rem">Unsere Schriftführer*innen</h3>
        <p>Karin Stempfhuber-Herde | Rhiannon Williams</p>
      </RowColFull>
      <RowColFull>
        <h3 className="d-pink padtop2rem">Die Beisitzer*innen</h3>
        <p>Katharina Bergmeier | Robert Forster | Enrico Weiser</p>
      </RowColFull>
      <RowColFull>
        <h3 className="d-pink padtop2rem">
          Die Vertretung der Jugendabteilung
        </h3>
        <p>
          Lisa Sommer (LA) | Amelie Kutz (SR)
          <br />
          Wolfgang Jester (DGF) | Ramon Denk (PAN)
          <br />
          Mail:{" "}
          <a
            className="d-pink"
            href="mailto:jugendgruppen@queer-niederbayern.de"
          >
            jugendgruppen@queer-niederbayern.de
          </a>
        </p>
      </RowColFull>
    </SubPages>
  );
}
