import RowColFull from "./UI/RowColFull";

export default function NewsItem(props) {
  return (
    <>
      <RowColFull className={props.className + " padtopbottom2rem"}>
        <h4 className={props.color || "d-blue"}>{props.title}</h4>
        {props.children}
      </RowColFull>
      {props.noHR ? "" : <hr />}
    </>
  );
}
