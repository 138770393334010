import { Col, Row } from "react-bootstrap";

import PicLink from "./PicLink";

export default function TextAndPic(props) {
  return (
    <Row className="padtopbottom1rem">
      <Col md>
        <p className="notjustified">
          {props.link ? (
            <a
              className={props.color ?? "d-pink"}
              href={props.link}
              target="_blank"
              rel="noreferrer"
            >
              {props.linkText}
            </a>
          ) : (
            ""
          )}{" "}
          {props.text}
          {props.extra}
        </p>
      </Col>

      {props.src ? (
        <Col md>
          <PicLink
            link={props.link || ""}
            className={props.className || "sponsor"}
            src={props.src}
            alt={props.alt || `${props.linkText} Logo`}
          />
        </Col>
      ) : (
        <Col md={6}></Col>
      )}
    </Row>
  );
}
