/**
 *  Simple picture component with link - possible props as params
 *
 *  @param {String} link      Link to page
 *  @param {String} className class names added to jsx
 *  @param {String} src       picture source, use a variable to make this possible with react
 *  @param {String} alt       alternative text for accessability
 *  @param {String} linkText  Sometimes used instead of alttext, just uses the linktext and adds " Logo"
 *
 *  @return     JSX
 */

export default function PicLink(props) {
  return (
    <>
      {props.link ? (
        <a href={props.link} target="_blank" rel="noreferrer">
          <img
            className={props.className}
            src={props.src}
            alt={props.alt || `${props.linkText} Logo`}
          />
        </a>
      ) : (
        <img
          className={props.className}
          src={props.src}
          alt={props.alt || `${props.linkText} Logo`}
        />
      )}
    </>
  );
}
