import SubPages from "../components/SubPages";
import RowColFull from "../components/UI/RowColFull";

export default function CSD() {
  return (
    <SubPages title="Christopher-Street-Days">
      <RowColFull>
        <p>
          Der Christopher Street Day (CSD) ist ein Fest-, Gedenk- und
          Demonstrationstag von Lesben, Schwulen, Bisexuellen, trans* und
          inter-Personen und allen queeren Menschen. Er erinnert an den Aufstand
          von Homosexuellen und anderen sexuellen Identitäten gegen
          Polizeigewalt und Repressionen am 28. Juni 1969 in der Bar „Stonewall
          Inn“ in der New Yorker Christopher-Street. Heute demonstrieren wir mit
          den CSDs gegen Ausgrenzung und Diskriminierung sowie für Toleranz und
          Akzeptanz. Deshalb ist der CSD jährlich eines unserer wichtigsten und
          größten Projekte.
        </p>
        <p>
          Unser erster CSD Niederbayern am 26. Sept. 2019 in Landshut war ein
          voller Erfolg und übertraf mit rund 2.500 Demonstrierenden und rund
          5.000 Feiernden beim bunten Straßenfest nicht nur unsere Erwartungen,
          sondern auch die der Stadt Landshut!
        </p>
        <p>
          Mit den niederbayerischen CSDs wollen wir die queere Community und
          viele andere Menschen erreichen. Wir bieten dabei viele verschiedene
          Programmpunkte und rundum ein buntes Angebot. Mit einer großen
          Demoparade ziehen wir durch die Innenstadt und demonstrieren für
          unsere Forderungen. Auf den bunten CSD-Straßenfesten wird an vielen
          Infoständen und mit einem vielseitigen Bühnenprogramm Raum für
          Austausch & Information, Vernetzung und Diskussion geboten. Soweit
          örtlich möglich wollen wir den CSD-Tag mit einer After-CSD-Party
          abschließen.
        </p>
        <p>
          Du hast Lust den CSD aktiv mitzugestalten? Dann werde Teil unserer AG
          CSD. Alle Informationen und Kontakte zur Arbeitsgruppe CSD findest du{" "}
          <a className="d-orange" href="./work-groups">
            hier
          </a>
          .
        </p>
      </RowColFull>
      <hr />
      <RowColFull className="padtop2rem">
        <h2 className="d-orange center">CSD-Termine 2024</h2>
      </RowColFull>
      <RowColFull className="padtopbottom2rem">
        <p className="center">
          5. CSD Kelheim: Samstag, 01. Juni 2024
          <br />
          5. CSD Straubing: Samstag, 27. Juli 2024
          <br />
          6. CSD Landshut: Samstag, 28. Sept. 2024
        </p>
        <p className="small center">
          CSDs sind für uns finanziell auch immer anspruchsvoll. Allein eine
          Bühne mit Ton- und Lichttechnik verursachen Kosten von rund 5.000
          Euro. <br />
          Du möchtest uns unterstützen? Sehr gerne kannst du uns deine Spende
          gerne per Überweisung zukommen lassen.
          <br />
          <br />
          Queer in Ndb. e. V.
          <br />
          IBAN: DE09 7435 0000 0020 9682 56
          <br />
          Sparkasse Landshut
          <br />
          Verwendungszweck: Spende
        </p>
      </RowColFull>
      <RowColFull className="padtop2rem">
        <h2 className="d-orange center">
          Warum wir auch heute noch Christopher-Street-Days brauchen
        </h2>
      </RowColFull>
      <RowColFull>
        <p>
          In den USA werden gerade in vielen Bundesstaaten queere Rechte
          beschnitten bzw. abgeschafft; so z. B. in Florida unter dem
          rechtskonservativen Gouverneur Ron DeSantis. So wird z. B. Aufklärung
          über LGBTIQ* in Schulen untersagt oder transfeindliche Gesetze neu
          beschlossen. Dies gilt mittlerweile auch für andere republikanisch
          regierten Bundesstaaten. Oder in Italien unter der
          rechtspopulistischen Regierung unter Ministerpräsidentin Georgia
          Meloni. Hier wurde u. a. das Namens- und Adoptionsrecht für Kinder in
          gleichgeschlechtlichen Partnerschaften sogar rückwirkend bis 2017
          abgeschafft. In Polen haben sich seit 2019 sehr viele Städte,
          Gemeinden und Provinzen als LSBT-ideologiefreie Zonen erklärt, um
          queere Menschen zu stigmatisieren und auszuschließen.
        </p>
        <p>
          Ungarn unter dem rechtskonservativen Ministerpräsidenten Orban hat in
          den Jahren Gesetze gegen LGBTIQ*-Menschen verschärft und Rechte
          eingeschränkt oder abgeschafft. Unter anderem wird dort trans* sein
          mit Pädophilie gleichgestellt. Seit 2020 stehen nun in der ungarischen
          Verfassung homophobe Passagen.
        </p>
        <p>
          Weltweit kann in 12 Ländern gegen Homosexuelle die Todesstrafe
          verhängt werden. Und auch in Deutschland diskriminieren die Rechten
          und Rechtskonservativen queere Menschen und wollen die über die Jahre
          errungenen Rechte und Gleichstellungen wieder abschaffen. Und für
          Minderheiten gibt es noch viele rechtliche Hürden. So ist das mehrfach
          in Teilen für verfassungs-widrig erklärte Transsexuellengesetz (TSG)
          immer noch nicht abgeschafft. Oder in einer lesbischen Ehe muss die
          nicht gebärdende Partnerin das Kind in einem aufwendigen
          Adoptionsverfahren adoptieren. Ähnliches gilt für schwule Ehen.
        </p>
        <p>
          In Bayern hat zwar Ministerpräsident Dr. Markus Söder im Frühjahr vor
          der Landtagswahl einen Queeren Aktionsplan Bayern angekündigt.
          Allerdings verhindern die Freien Wähler und insbesondere deren
          Vorsitzender und stellvertretender Ministerpräsident Hubert Aiwanger
          einen solchen Queeren Aktionsplan Bayern. So haben die Freien Wähler
          und Hubert Aiwanger mehrfach verhindert, dass der Queere Aktionsplan
          Bayern auf die Tagesordnung des Kabinetts kommt. Und den Versuch, den
          Queeren Aktionplan Bayern in den Koalitionsvertrag zu schreiben, hat
          wiederum Hubert Aiwanger persönlich verhindert.
        </p>
        <p>
          Alle diese Beispiele zeigen, dass es noch immer wichtig ist,
          Christopher-Street-Days abzuhalten und weiter für queere Rechte
          kämpfen.
        </p>
      </RowColFull>
    </SubPages>
  );
}
