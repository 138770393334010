import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";

import Logo from "../../assets/img/qin-logo.png";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./MainNavigation.module.css";
import { useState } from "react";
import "./nav.css";
import Beitrittserklaerung from "../../assets/files/Beitrittserklärung.pdf";
import Beitragsordnung from "../../assets/files/Beitragsordnung.pdf";
import Satzung from "../../assets/files/Satzung.pdf";

const active = ({ isActive }) => isActive && styles.active;

function MainNavigation() {
  const [menuOpen, toggleMenuOpen] = useState(false);
  const location = useLocation();
  let mobile = isMobile();

  const nr0 = `${styles.no0} ${active}`;
  const nr1 = `${styles.no1} ${active}`;
  const nr2 = `${styles.no2} ${active}`;
  const nr3 = `${styles.no3} ${active}`;
  const nr4 = `${styles.no4} ${active}`;
  const nr5 = `${styles.no5} ${active}`;

  return (
    <header className={styles.header}>
      <Navbar.Brand href="/">
        <img
          className="logo"
          src={Logo}
          align="left"
          alt="Logo Queer in Niederbayern e.V."
        />
      </Navbar.Brand>
      <Navbar className="ml-auto" collapseOnSelect expand="lg" variant="dark">
        <Container>
          <Navbar.Brand />
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav defaultActiveKey="/home">
              {customNavItem("1", "/", nr0, "Home", [
                { link: "about", title: "Über uns" },
                { link: "board", title: "Vorstand" },
                {
                  link: Beitrittserklaerung,
                  title: "Beitrittserklärung",
                },
                {
                  link: Beitragsordnung,
                  title: "Beitragsordnung",
                },
                {
                  link: Satzung,
                  title: "Satzung",
                },
                { link: "links", title: "Links" },
              ])}
              {customNavItem("2", "/activities", nr1, "Aktivitäten", [
                { link: "csd", title: "CSDs" },
                { link: "koalaTee", title: "koalaTee" },
                { link: "regulars-table", title: "LSBTIQ*-Stammtische" },
                { link: "parentgroup", title: "Elternstammtisch" },
                { link: "gayFathers", title: "Schwule Väter und Ehemänner" },
                { link: "schools", title: "Schulprojekt" },
                { link: "work-groups", title: "Arbeitsgruppen" },
              ])}
              {customNavItem("3", "/youthgroups", nr2, "Jugend", [
                { link: "youth-meetings", title: "Gruppen" },
              ])}
              {customNavItem("4", "/guidance", nr3, "Beratungsstelle")}
              {customNavItem("5", "/csdAndNews", nr4, "News & Infos", [
                { link: "news", title: "News" },
                { link: "goals", title: "Ziele" },
                { link: "terms", title: "Begriffe" },
                { link: "dates", title: "Termine" },
              ])}
              {customNavItem("6", "/contact", nr5, "Kontakt")}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );

  function isMobile() {
    let mobile = false;
    const mobileScreen = window.matchMedia("(max-width: 995px)");
    if (mobileScreen.matches) {
      mobile = true;
    }
    return mobile;
  }

  /**
   *  Makes a nice nav item, no component needed because it really is super simple
   *
   *  @param {String} key       Just a number as a string, count up, starting with 1 - don't worry about it
   *  @param {String} link      Link to subpage
   *  @param {String} className class names added to jsx
   *  @param {String} title     Title that is shown in the menu
   *  @param {Array.<{link: String, title: String}>}  dropDown  Dropdown items. If filled the menu changed to accomodate dropdowns. Item with link and title.
   *
   *  @return     correct JSX to use in navigation :)
   */

  function customNavItem(key, link, className, title, dropDown) {
    if (dropDown && dropDown.length > 0 && !mobile) {
      return renderDesktopDropdown(key, link, className, title, dropDown);
    } else if (dropDown && dropDown.length > 0 && mobile) {
      return renderMobileDropdown(key, link, className, title, dropDown);
    } else {
      return renderNavLink(key, link, className, title);
    }
  }

  // Render the dropdown for desktop
  function renderDesktopDropdown(key, link, className, title, dropDown) {
    return (
      <div onMouseLeave={() => toggleMenuOpen(false)}>
        <NavDropdown
          title={title}
          id="basic-nav-dropdown"
          href={link}
          className={`${className} ${
            isActivePath(location.pathname, link) ? styles.active : ""
          }`}
          onMouseEnter={() => toggleMenuOpen(key)}
          onToggle={() => {
            window.location.href = link;
          }}
          show={key === menuOpen}
        >
          <div className={"no" + (Number(key) - 1)}>
            {dropDown.map((item, index) => (
              <NavDropdown.Item
                key={index}
                href={item.link}
                onClick={() => toggleMenuOpen(false)}
                className="center"
              >
                {item.title}
              </NavDropdown.Item>
            ))}
          </div>
        </NavDropdown>
      </div>
    );
  }

  // Render the dropdown for mobile
  function renderMobileDropdown(key, link, className, title, dropDown) {
    return (
      <NavDropdown title={title} id="basic-nav-dropdown" className={className}>
        <div className={"no" + (Number(key) - 1)}>
          <NavDropdown.Item key="99" href={link} className="center">
            {title}
          </NavDropdown.Item>
          {dropDown.map((item, index) => (
            <NavDropdown.Item key={index} href={item.link} className="center">
              {item.title}
            </NavDropdown.Item>
          ))}
        </div>
      </NavDropdown>
    );
  }

  // Render a regular NavLink if there is no dropdown
  function renderNavLink(key, link, className, title) {
    return (
      <Nav.Link
        eventKey={key}
        as={NavLink}
        to={link}
        className={`${className} ${
          isActivePath(location.pathname, link) ? styles.active : ""
        }`}
      >
        {title}
      </Nav.Link>
    );
  }
}

/**
 *  Take the current location and the link and return true if the
 *  navigation style should be active or not
 */
function isActivePath(location, link) {
  if (location === link || location === "/" + link) {
    return true;
  } else if (
    (location === "/news" || location === "/dates") &&
    link === "/csdAndNews"
  ) {
    return true;
  } else if (
    (location === "/koalaTee" ||
      location === "/parentgroup" ||
      location === "/csd" ||
      location === "/regulars-table" ||
      location === "/schools" ||
      location === "/work-groups" ||
      location === "/gayFathers") &&
    link === "/activities"
  ) {
    return true;
  } else if (location === "/youth-meetings" && link === "/youthgroups") {
    return true;
  } else if (
    (location === "/links" || location === "/about" || location === "/board") &&
    link === "/"
  ) {
    return true;
  }

  return false;
}

export default MainNavigation;
