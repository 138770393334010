import RowColResponsiveText from "../components/UI/RowColResponsiveText";
import SubPages from "../components/SubPages";

export default function Terms() {
  return (
    <SubPages title="Begriffe">
      <RowColResponsiveText
        items={[
          {
            item: "Bezeichnung für Personen, die wenig oder kein Bedürfnis an sexuellen bzw. romantischen Kontakten mit anderen Personen haben.",
            color: "d-pink",
            header: "Asexuell/ aromantisch:",
          },
          {
            item: "(„Bi“=“Zwei“) bezeichnet Männer und Frauen, die sich zu Männern und Frauen sexuell hingezogen fühlen und/oder Frauen und Männer lieben.",
            color: "d-orange",
            header: "Bisexuell/ biromantisch:",
          },
          {
            item: "(cis, lateinisch: diesseits) bezeichnet, dass eine Person in Übereinstimmung mit ihrem bei Geburt zugewiesenen Geschlecht lebt. Cis-geschlechtlich zu sein entspricht, im Gegensatz zu trans*geschlechtlich, der Norm.",
            color: "d-yellow",
            header: "Cis-geschlechtlich:",
          },
          {
            item: "Der Prozess, sich über etwas bewusst zu werden (inneres Coming Out) und dies eventuell anderen Personen mitzuteilen (äußeres Coming Out).",
            color: "d-green",
            header: "Coming-out:",
          },
          {
            item: "Selbstbezeichnung eines Menschen, der es “einfach nur schön” findet, Kleidung zu tragen, die nicht mit ihrem eigenen Geschlecht assoziiert wird. Das Tragen von Kleidung sagt nichts über die Geschlechtliche Identität dieser Personen aus.",
            color: "d-blue",
            header: "Cross Dresser*in:",
          },
          {
            item: "Das Gegenteil von Privilegierung. Es bedeutet, dass ein Mensch benachteiligt, also schlechter behandelt wird, weil dieser zum Beispiel trans*, weiblich, schwarz, homosexuell, behindert oder arm ist. Menschen wird aufgrund gewisser Merkmale Zugehörigkeit zu bestimmten Gruppen zugeschrieben.",
            color: "d-purple",
            header: "Diskriminierung:",
          },
          {
            item: "Steht für die Abkürzung “Dressed as Girl” und später auch für “Dressed as Guy”. Es ist eine Kunstform, die gesellschaftliche Geschlechterklischees übertreibt und sarkastisch betrachtet.",
            color: "d-pink",
            header: "Drag:",
          },
          {
            item: "Die Überzeugung, dass alle Menschen einer Gesellschaft, unabhängig von ihrem Geschlecht, gleiche Rechte, Chancen und Pflichten haben sollten.",
            color: "d-orange",
            header: "Feminismus:",
          },
          {
            item: "Menschen werden meistens bereits vor der Geburt von Ärzt*innen auf Grund von körperlichen Merkmalen entweder als Junge oder Mädchen eingeteilt. Es geschieht eine Zweiteilung in Junge und Mädchen, obwohl körperliche Merkmale sehr unterschiedlich sein können. Für manche Menschen ist diese Einteilung im Laufe des Lebens nicht stimmig. Gerade bei intersexuellen Kindern werden oft direkt nach der Geburt medizinisch unnötige „geschlechtsangleichende“ Operationen durchgeführt, mit dem Zweck, dass die Kinder in die zweigeschlechtliche Norm passen.",
            color: "d-yellow",
            header: "Geschlecht:",
          },
          {
            item: "Das Sternchen oder sog. “Wildcard” kommt aus der Computersprache und dient dort als Platzhalter für eine beliebige Anzahl und Kombination von Buchstaben. Hinter Trans* sollen dadurch alle verschiedenen Identitäten, wie beispielsweise “mit Transitionserfahrung”, Transsexuell oder Transgender miteingeschlossen werden.",
            color: "d-green",
            header: "Gender Star*:",
          },
          {
            item: "Selbstbezeichnung für Frauen*Mädchen, die sich zu Männern*Jungen sexuell hingezogen fühlen/sich verlieben bzw. umgekehrt. Häufig wird davon ausgegangen, dass alle Menschen heterosexuell sind. Das wird Heteronormativität genannt.",
            color: "d-blue",
            header: "Heterosexuell / heteroromantisch:",
          },
          {
            item: "Binäres, zweigeschlechtliches und heterosexuell organisiertes und organisierendes Wahrnehmungs-, Handlung- und Denkschema. Dieses Schema basiert auf der vermeintlichen natürlichen Gegebenheit der Heterosexualität (Mann begehrt Frau, Frau begehrt Mann).",
            color: "d-purple",
            header: "Heteronormativität:",
          },
          {
            item: "Überbegriff für „Lesbisch“ und „Schwul“. Er kommt aus dem griechischen: „homo“=“gleich“.",
            color: "d-pink",
            header: "Homosexuell / homoromantisch:",
          },
          {
            item: "Umschreibt die gelebte Erfahrung, mit einem Körper geboren zu sein, der den gewöhnlichen Vorstellungen von männlich und weiblich nicht entspricht. Inter* Menschen haben beispielsweise sowohl männliche als auch weibliche Geschlechtsorgane. Dies führt noch heute zu Diskriminierungen und Menschenrechtsverletzungen, wie z.B. uneingewilligte geschlechtsverändernde Eingriffe. Inter* Personen können cis-geschlechtlich oder trans* sein und alle Selbstbezeichnungen von sexueller und romantischer Orientierung für sich verwenden.",
            color: "d-orange",
            header: "Inter*:",
          },
          {
            item: "beschreibt die Überschneidung von verschiedenen Diskriminierungsformen in einer Person. Diskriminierungsformen wie Rassismus, Sexismus, Homophobie, Behindertenfeindlichkeit oder Klassismus addieren sich nicht nur in einer Person, sondern führen zu eigenständigen Diskriminierungserfahrungen. So wird eine farbige Frau* gegebenenfalls nicht nur als Frau und als person of colour diskriminiert, sondern sie kann auch die Erfahrung machen, als farbige Frau* diskriminiert zu werden.",
            color: "d-yellow",
            header: "Intersektionalität:",
          },
          {
            item: "Mono-Normativität bezeichnet die Wissensproduktionen, Machttechnologien und Praktiken, die eine exklusiv dyadische Struktur von Paarbeziehungen als elementare und ’natürliche‘ Form des Zusammenlebens produzieren. Nicht-monogame Konstellationen werden demgegenüber pathologisiert und als Effekt oder Verursachung eines Mangels oder Persönlichkeitsdefizits interpretiert.",
            color: "d-green",
            header: "Mononormativität:",
          },
          {
            item: "Selbstbezeichnungen für Menschen, die sich außerhalb der Einteilung in zwei Geschlechter verorten. Das kann bedeuten, dass sich ein Mensch weder weiblich noch männlich versteht oder nicht ausschließlich.",
            color: "d-blue",
            header: "Nichtbinär, Genderqueer, Genderfluid, Agender,…:",
          },
          {
            item: "(Griechisch: „Pan“=“Alle“) Bezeichnung für Personen, die sich zu Personen jeden Geschlechts sexuell hingezogen fühlen und/oder Personen jeden Geschlechts lieben.",
            color: "d-purple",
            header: "Pansexuell / Panromantisch:",
          },
          {
            item: "Gegenteil von Diskriminierung. Es bedeutet, dass ein Mensch bevorzugt, also besser behandelt wird, weil dieser zum Beispiel männlich, cis-geschlechtlich, weiß, heterosexuell, nicht behindert oder reich ist.",
            color: "d-pink",
            header: "Privilegierung:",
          },
          {
            item: "schließt vielfältige Formen von sexueller/romantischer Orientierung und Geschlechtsidentitäten ein, die von Hetero- und Mononormativität abweichen.",
            color: "d-orange",
            header: "Queer:",
          },
          {
            item: "(lateinisch: jenseits) bedeutet, dass eine Person jenseits des Geschlechts lebt, das bei der Geburt zugewiesen wurde.",
            color: "d-yellow",
            header: "Trans*:",
          },
          {
            item: "eine Abkürzung im deutschsprachigen Raum. Die Buchstaben stehen für Lesbisch-Schwul-Bi-Trans*-Queer*-Inter*. Das Sternchen hinter der Abkürzung steht für mögliche Erweiterungen.",
            color: "d-green",
            header: "LGBTIQ*:",
          },
        ]}
      />
    </SubPages>
  );
}
