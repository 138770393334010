import SubPages from "../components/SubPages";
import RowColFull from "../components/UI/RowColFull";
import { Col, Row } from "react-bootstrap";

const subtitle = (
  <p>
    Unsere queeren Jugendgruppen sind ein Angebot für schwule, lesbische,
    bisexuelle, trans*, inter* und alle anderen nicht heterosexuellen jungen
    Menschen. Wir sind Jugendliche und junge Erwachsene, die ehrenamtlich und
    nach dem peer-to-peer-Ansatz queere Jugendarbeit gestalten - von jungen
    Menschen für junge Menschen. Eingeladen sind aber auch alle diejenigen, die
    mit dem Thema LSBTIQA* nichts anfangen können, denn: es soll aufgeklärt und
    Vorurteile abgebaut werden. Die Treffen der Jugendgruppen sind auch immer
    ein safe space. Also traut euch, wir freuen uns auf euch.
  </p>
);

export default function YouthMeetings() {
  return (
    <SubPages title="Unsere queeren Jugendgruppen" subtitle={subtitle}>
      <RowColFull>
        <h3 className="d-yellow">Landshut</h3>
        <p>
          Unsere queere Jugendgruppe Landshut war unsere erste Jugendgruppe.
          Schon kurz nach unserem 1. CSD Niederbayern hat sie sich im November
          2019 gegründet.
        </p>
        <p>
          Unsere Landshuter Jugendgruppe trifft sich
          <br></br>
          Wann: jeden 1. und 3. Montag im Monat ab ##.## Uhr
          {/* TODO Uhrzeit */}
          <br></br>
          Wo: teilen wir dir auf Anfrage mit<br></br>
          Gruppenleitung: Lisa, Joshua, Victoria
          <br></br>
          Kontakt:{" "}
          <a
            className="d-yellow"
            href="mailto:la-jugendgruppe@queer-niederbayern.de"
          >
            la-jugendgruppe@queer-niederbayern.de
          </a>
        </p>
        <p>
          Mit unserer queeren Jugendgruppe Landshut sind wir Mitglied im
          Stadtjugendring Landshut.
        </p>
      </RowColFull>

      <hr></hr>
      <RowColFull>
        <h3 className="d-yellow">Straubing</h3>
        <p>
          Kaum ein halbes Jahr nach unserer ersten Jugendgruppe in Landshut hat
          sich im Frühjahr 2020 unsere queere Jugendgruppe Straubing (für die
          Stadt Straubing und den Landkreis Straubing-Bogen) gegründet.
        </p>
        <p>
          Die Straubinger Jugendgruppe trifft sich
          <br></br>
          Wann: 1 bis 2 mal im Monat donnerstags, ab ##:## Uhr
          {/* TODO Uhrzeit */}
          <br></br>
          Wo: teilen wir dir auf Anfrage mit<br></br>
          GruppenLeitung: Chris und Amelie
          <br></br>
          Kontakt:{" "}
          <a
            className="d-yellow"
            href="mailto:sr-jugendgruppe@queer-niederbayern.de"
          >
            sr-jugendgruppe@queer-niederbayern.de
          </a>
        </p>
        <p>
          Mit unserer queeren Jugendgruppe Straubing sind wir Mitglied im
          Stadtjugendring Straubing.
        </p>
      </RowColFull>
      <hr></hr>
      <RowColFull>
        <h3 className="d-yellow">Dingolfing</h3>
        <p>
          Die queere Jugendgruppe Diversity Dingolfing ist vor einiger Zeit
          unter das Dach unseres Vereins gekommen. Seither bieten wir für die
          Stadt Dingolfing und auch für den Landkreis Dingolfing-Landau allen
          jungen LSBTIQA*-Menschen im Alter von ca. 14 Jahren bis 27 Jahren eine
          Jugendgruppe in einem safe space.
        </p>
        <p>
          Unsere Jugendgruppe Dingolfing trifft sich
          <br></br>
          Wann: Am 1. Samstag im Monat, ab 16:00 Uhr bis ca. 20:00 Uhr
          <br></br>
          Wo: teilen wir dir auf Anfrage mit
          <br></br>
          GruppenLeitung: Wolfgang Jester
          <br></br>
          Kontakt:<br></br>
          <a
            className="d-yellow"
            href="mailto:diversity-dingolfing@queer-niederbayern.de"
          >
            diversity-dingolfing@queer-niederbayern.de
          </a>
          <br></br>
          <a
            className="d-yellow"
            href="mailto:dgf-jugendgruppe@queer-niederbayern.de"
          >
            dgf-jugendgruppe@queer-niederbayern.de
          </a>
        </p>
      </RowColFull>
      <hr></hr>
      <RowColFull>
        <h3 className="d-yellow">Deggendorf</h3>
        <p>
          Auch unsere Jugendgruppe in Deggendorf richtet sich an alle
          LSBTIQ*-Jugendliche und junge Erwachsene aus der Stadt und dem
          Landkreis Deggendorf sowie aus dem Landkreis Regen. Zur Zeit ist die
          Jugendgruppe aber leider nicht wirklich aktiv. Wir suchen nämlich für
          unsere queere Jugendgruppe, die sich sonst in der Regel 14-tägig
          trifft, eine neue Gruppenleitung (m/w/d*). Wenn du also Interesse
          hast, dich an unserer queeren Jugendgruppe DEG zu beteiligen,
          mithelfen möchtest, diese wieder an den Start bringen oder gar Lust
          hast, dich in die Organisation der Jugendgruppe einzubringen, dann
          schreib einfach eine Mail an{" "}
          <a
            className="d-yellow"
            href="mailto:deg-jugendgruppe@queer-niederbayern.de"
          >
            deg-jugendgruppe@queer-niederbayern.de
          </a>{" "}
          und unser Jugendvorstand nimmt gerne Kontakt zu dir auf.
        </p>
        <p>
          Mit unserer queeren Jugendgruppe Deggendorf sind wir Mitglied im
          Kreisjugendring Deggendorf.
        </p>
      </RowColFull>
      <hr></hr>
      <RowColFull>
        <h3 className="d-yellow">Pfarrkirchen (Rottal-Inn)</h3>
        <p>
          Unsere LSBTIQ*-Jugendgruppe für Pfarrkirchen ist derzeit, was ihr
          Bestehen betrifft, unsere jüngste Jugendgruppe. Auch sie richtet sich
          an alle schwulen, lesbischen, bisexuellen, trans*, inter* und alle
          queeren Jugendliche und jungen Erwachsene aus dem gesamten Landkreis
          Rottal-Inn. Wo wir uns treffen, teilen wir euch gerne auf Anfrage mit.
          Wenn ihr also im Landkreis Rottal-Inn zuhause seid, schon immer
          Gleichgesinnte treffen wolltet oder wenn ihr einfach so Interesse an
          unserer LSBTIQ*-Jugendgruppe habt, dann kontaktiert die Leitung der
          Jugendgruppe unter{" "}
          <a
            className="d-yellow"
            href="mailto:pan-jugendgruppe@queer-niederbayern.de "
          >
            pan-jugendgruppe@queer-niederbayern.de
          </a>
          . Unser Jugendgruppenleiter Rämsy (Ramon) freut sich auf eure Mail und
          informiert euch über alles Wissenswerte.
        </p>
        <p>
          Mit unserer queeren Jugendgruppe Pfarrkirchen / Rottal-Inn sind wir
          Mitglied im Kreisjugendring Rottal-Inn.
        </p>
      </RowColFull>
      <hr></hr>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <h3 className="d-yellow center">
            Keine Jugendgruppe in eurer Gegend gefunden?
          </h3>
          <p className="center">
            Ihr seid in Niederbayern in einer Gegend zuhause, in der ihr oben
            keine für euch erreichbare Jugendgruppe gefunden habt? Kein Problem,
            dann meldet euch bei uns, nehmt Kontakt zu unserem Jugendvorstand
            auf und wir schauen gemeinsam, welches Angebot wir euch machen
            können. Gegebenenfalls gründet sich in eurer Region (z. B. in
            Kelheim oder in Passau) eine queere Jugendgruppe.{" "}
          </p>
        </Col>
      </Row>
    </SubPages>
  );
}
