import SubPages from "../components/SubPages";
import EventDateAndText from "../components/calendar/EventDateAndText";

export default function Dates() {
  return (
    <SubPages title="Anstehende Termine">
      <EventDateAndText
        events={[
          {
            day: "Samstag",
            dateDate: "27. Juli",
            year: "2024",
            text: `<p>Geplant mit Ausweichtermin am 20.07.24 der 5. <strong class="d-orange"> CSD in Straubing</strong>.</p>`,
          },
          {
            day: "Samstag",
            dateDate: "03. August",
            year: "2024",
            text: `<p>Teilnahme als Fußgruppe am Demozug beim Jubiläums-<br><strong class="d-yellow">CSD Nürnberg</strong>. Hier ist es bereits der 25. CSD.</p>`,
          },
          {
            day: "Samstag",
            dateDate: "28. September",
            year: "2024",
            text: `<p>Wie immer am letzten Samstag im September, der 6.<strong class="d-green"> CSD Landshut</strong>.</p>`,
          },
          {
            day: "Samstag",
            dateDate: "11. Oktober",
            year: "2024",
            text: `<p>Aktion zum <strong class="d-blue"> Coming Out Day</strong>.</p>`,
          },
          {
            day: "Samstag",
            dateDate: "30. November",
            year: "2024",
            text: `<p>Aktion zum <strong class="d-purple"> Welt-Aids-Tag</strong> am 01. Dezember.</p>`,
          },
        ]}
      ></EventDateAndText>
    </SubPages>
  );
}
